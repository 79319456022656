.quote {
  position: relative;

  display: grid;

  padding-top: var(--distancer);
  padding-right: calc(var(--distancer) / 3);
  padding-bottom: var(--distancer);
  padding-left: calc(var(--distancer) / 3);

  border-top: 1px solid var(--color-n-blue);
  border-bottom: 1px solid var(--color-n-blue);

  grid-template-columns: 30px 1fr 1fr;
  grid-gap: calc(var(--distancer) / 2);

  font-size: var(--font-size);
}

.quote:hover {
  background-color: var(--color-item-hover);
}

.is-focus,
.is-focus.quote:hover {
  background-color: var(--color-item-focus);
}

.tag {
  position: absolute;
  top: -7px;
  left: var(--distancer);
}

.vote {
  grid-row: 1 / span 2;
  grid-column: 1/2;

  text-align: center;

  font-family: var(--font-family-mono);
}

.vote-up,
.vote-down {
  width: calc(var(--distancer));
  height: calc(var(--distancer));

  color: var(--color-n-gray);

  font-size: var(--font-size-big);
  font-weight: bold;
}

.vote-up {
  margin-bottom: var(--distancer-small);
}

.vote-down {
  margin-top: var(--distancer-small);
}

.contact {
  grid-row: 1;
  grid-column: 2/3;

  font-weight: bold;
}

.created-at {
  grid-row: 1;
  grid-column: 3/4;

  text-align: right;
}

.body {
  grid-row: 2;
  grid-column: 2/4;
}

.body p:last-child {
  margin-bottom: 0;
}

.source {
  overflow: hidden;

  width: 350px;
  margin-top: calc(var(--distancer) / 2);

  white-space: nowrap;
  text-overflow: ellipsis;

  font-style: italic;
}

.placeholder {
  color: var(--color-n-gray);
}
