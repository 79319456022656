.board {
  position: relative;

  display: grid;
  overflow: hidden;

  width: var(--workspace-width);

  /*
   * 186px - features list height
   * 65px - cmd line height
   */
  height: calc(100vh - 186px - 65px);
  margin: 0 auto;
  padding-top: var(--distancer-small);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0;
}
