.metric {
  position: relative;
}

.title {
  overflow: hidden;

  margin-bottom: calc(var(--distancer) / 3);

  white-space: nowrap;
  text-overflow: ellipsis;

  font-family: var(--font-family-mono);
  font-size: var(--font-size-tiny);
}

.chart {
  overflow: hidden;

  height: 50px;
}

.chart--empty {
  padding: var(--distancer);

  text-align: center;

  font-size: var(--font-size-medium);
}

.chart--empty small {
  display: block;

  margin-top: calc(var(--distancer) / 2);

  font-size: var(--font-size-medium);
}
