/* stylelint-disable no-descending-specificity */
@import "sanitize.css";
@import "simplebar/dist/simplebar.min.css";
@import "./fonts.css";
@import "./emoji.css";

body {
  color: var(--color-black);
  background-color: var(--color-white);

  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  line-height: var(--line-height);
}

::selection {
  color: var(--color-black);
  background: var(--color-n-yellow);
}

hr {
  height: 1px;
  margin: var(--distancer) 0;

  border: 0;
  background: var(--color-n-gray);
}

h1 {
  margin-top: var(--distancer);
  margin-bottom: var(--distancer);

  font-size: var(--font-size-huge);
}

h1 small {
  font-size: var(--font-size-medium);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;

  font-weight: bold;
}

h1:first-child {
  margin-top: 0;
}

h2,
h3 {
  margin-top: calc(var(--distancer) / 3);
  margin-bottom: calc(var(--distancer) / 3);

  font-size: var(--font-size-big);

  small {
    line-height: 1.2;
  }
}

h2:first-child {
  margin-top: 0;
}

h3 {
  font-size: var(--font-size-medium);
}

h3:first-child {
  margin-top: 0;
}

blockquote {
  margin: 0 0 var(--distancer) 0;
}

strong,
b {
  font-weight: bold;
}

strong {
  color: var(--color-black);
}

em {
  color: var(--color-black);
}

h1 em,
h2 em {
  display: inline-block;

  background: transparent url("./_images/background-em.png") center center no-repeat;
  background-size: contain;

  font-style: normal;
}

p {
  margin: 0 0 calc(var(--distancer) / 2) 0;
}

figure {
  margin: 0;
}

textarea,
textarea:focus {
  border: 0;
  outline: none;
}

a {
  text-decoration: none;

  color: var(--color-n-red);
}

a:hover {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

li code,
p code {
  /* Fira Code is bigger than Proxima */
  font-size: calc(var(--font-size) - 1px);
}

/*
 *
 */

:global(.simplebar-scrollbar::before),
:global(.simplebar-scrollbar.simplebar-visible::before) {
  right: 0;
  left: 0;

  border-radius: 0;
}

:global(.simplebar-scrollbar.simplebar-visible::before) {
  opacity: 1;
}

:global(.simplebar-track.simplebar-horizontal) {
  height: 3px;
}

:global(.simplebar-track.simplebar-vertical) {
  width: 3px;
}

:global(.simplebar-track.simplebar-horizontal .simplebar-scrollbar) {
  top: 0;

  height: var(--distancer-small);
}
