.worker {
  position: relative;

  width: 30px;

  font-size: var(--font-size-medium);
  font-weight: normal;
}

.worker--is-open {
  width: auto;
}

.worker--is-open .worker-content {
  position: absolute;
  z-index: var(--z-index-high);
  top: -6px;
  left: -6px;

  padding: 5px;

  border: 1px solid var(--color-n-gray);
  background: var(--color-white);
}

.avatar {
  display: block;

  margin-bottom: var(--distancer-small);

  cursor: pointer;
  white-space: nowrap;

  font-family: var(--font-family-mono);
}

.avatar:hover {
  background-color: var(--color-n-yellow);
}

.avatar:last-child {
  margin-bottom: 0;
}
