.avatar {
  position: relative;
  z-index: 1;

  display: inline-block;

  margin: 0;

  transition: opacity 0.2s ease, width 0.2s ease, height 0.2s ease;

  border-radius: var(--radius-small);
  background-color: var(--color-white);

  font-family: var(--font-family-mono);
  line-height: 1;
  filter: grayscale(100%);
}

.avatar--is-online {
  filter: none;
}

.name {
  padding-right: var(--distancer-small);
}

.avatar--has-name img,
.avatar--has-name svg {
  margin-right: var(--distancer-small);
}

.avatar img,
.avatar svg {
  width: 100%;
  height: 100%;

  border-radius: var(--radius-small);
}

.tooltip {
  position: fixed;
  z-index: var(--z-index-high);

  padding: var(--distancer-small);

  color: var(--color-black);
  background-color: var(--color-n-yellow);

  font-family: var(--font-family-mono);
  font-size: var(--font-size-tiny);
  line-height: 1;
}
