.textarea {
  display: block;
  overflow-y: hidden;

  width: 100%;
  min-height: calc(var(--line-height) * var(--font-size));

  resize: none;

  background: transparent;
}

.textarea::placeholder {
  opacity: 0.3;
}

.textarea::selection {
  color: var(--color-n-yellow);
  background: var(--color-black);
}
