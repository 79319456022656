.field {
  position: relative;

  padding: calc(var(--distancer) / 3);
}

.field:hover {
  background-color: var(--color-item-hover);
}

.field--is-focus,
.field--is-focus.field:hover {
  background-color: var(--color-item-focus);
}

.input {
  display: none;
}

.image {
  text-align: center;

  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.image img {
  max-width: 100%;
  max-height: 356px;

  cursor: pointer;
}

.field--is-focus .image,
.field:hover .image {
  mix-blend-mode: multiply;
}
