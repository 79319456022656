.sibling-wrapper {
  margin-left: var(--distancer);
  padding: var(--distancer-small) 0;

  cursor: pointer;

  border-bottom: 1px solid var(--color-n-gray);

  font-size: var(--font-size);
}

.sibling {
  position: relative;

  padding: calc(var(--distancer) / 3);
}

.sibling + .status {
  margin-top: var(--distancer);
}

.sibling:hover {
  background-color: var(--color-item-hover);
}

.sibling--is-select,
.sibling--is-select.sibling:hover {
  background-color: var(--color-item-select);
}

.sibling--is-focus,
.sibling--is-focus.sibling:hover {
  background-color: var(--color-item-focus);
}

.avatar {
  float: left;

  margin-right: calc(var(--distancer) / 2);
  margin-bottom: 0;
}

.sibling--is-select .avatar,
.sibling--is-focus .avatar,
.sibling:hover .avatar, {
  mix-blend-mode: multiply;
}
