.footer-form {
  margin-bottom: calc(var(--distancer) / 2);
}

.cli-wrapper {
  position: fixed;
  z-index: var(--z-index-higher);
  top: 66px;
  right: calc((100% - 1120px) / 2 + 30px);

  width: 420px;

  border-right: 1px solid var(--color-n-gray);
  border-bottom: 1px solid var(--color-n-gray);

  border-left: 1px solid var(--color-n-gray);

  background-color: var(--color-white);
  box-shadow: var(--box-shadow-big);
}

.demo-login-wrapper {
  position: fixed;
  z-index: var(--z-index-higher);
  top: 66px;
  right: calc((100% - 1120px) / 2 + 30px + 240px);

  width: 420px;

  border-right: 1px solid var(--color-n-gray);
  border-bottom: 1px solid var(--color-n-gray);

  border-left: 1px solid var(--color-n-gray);

  background-color: var(--color-white);
  box-shadow: var(--box-shadow-big);
}
