@media
  all and (-webkit-min-device-pixel-ratio: 1),
  all and (--moz-min-device-pixel-ratio: 1),
  all and (-o-min-device-pixel-ratio: 1),
  all and (min-resolution: 96dpi) {
  :global(.emoji) {
    background: transparent url("./_images/emoji-sprite.png") 0 0 no-repeat;
  }
}

@media
  all and (-webkit-min-device-pixel-ratio: 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min-resolution: 144dpi) {
  :global(.emoji) {
    background: transparent url("./_images/emoji-sprite-2x.png") 0 0 no-repeat;
    background-size: 28064px 22px;
  }
}

:global(.emoji) {
  display: inline-block;

  width: 22px;
  height: 22px;

  vertical-align: middle;
}

#e_1 {
  background-position: -5px 0;
}

#e_2 {
  background-position: -37px 0;
}

#e_3 {
  background-position: -69px 0;
}

#e_4 {
  background-position: -101px 0;
}

#e_5 {
  background-position: -133px 0;
}

#e_6 {
  background-position: -165px 0;
}

#e_7 {
  background-position: -197px 0;
}

#e_8 {
  background-position: -229px 0;
}

#e_9 {
  background-position: -261px 0;
}

#e_10 {
  background-position: -293px 0;
}

#e_11 {
  background-position: -325px 0;
}

#e_12 {
  background-position: -357px 0;
}

#e_13 {
  background-position: -389px 0;
}

#e_14 {
  background-position: -421px 0;
}

#e_15 {
  background-position: -453px 0;
}

#e_16 {
  background-position: -485px 0;
}

#e_17 {
  background-position: -517px 0;
}

#e_18 {
  background-position: -549px 0;
}

#e_19 {
  background-position: -581px 0;
}

#e_20 {
  background-position: -613px 0;
}

#e_21 {
  background-position: -645px 0;
}

#e_22 {
  background-position: -677px 0;
}

#e_23 {
  background-position: -709px 0;
}

#e_24 {
  background-position: -741px 0;
}

#e_25 {
  background-position: -773px 0;
}

#e_26 {
  background-position: -805px 0;
}

#e_27 {
  background-position: -837px 0;
}

#e_28 {
  background-position: -869px 0;
}

#e_29 {
  background-position: -901px 0;
}

#e_30 {
  background-position: -933px 0;
}

#e_31 {
  background-position: -965px 0;
}

#e_32 {
  background-position: -997px 0;
}

#e_33 {
  background-position: -1029px 0;
}

#e_34 {
  background-position: -1061px 0;
}

#e_35 {
  background-position: -1093px 0;
}

#e_36 {
  background-position: -1125px 0;
}

#e_37 {
  background-position: -1157px 0;
}

#e_38 {
  background-position: -1189px 0;
}

#e_39 {
  background-position: -1221px 0;
}

#e_40 {
  background-position: -1253px 0;
}

#e_41 {
  background-position: -1285px 0;
}

#e_42 {
  background-position: -1317px 0;
}

#e_43 {
  background-position: -1349px 0;
}

#e_44 {
  background-position: -1381px 0;
}

#e_45 {
  background-position: -1413px 0;
}

#e_46 {
  background-position: -1445px 0;
}

#e_47 {
  background-position: -1477px 0;
}

#e_48 {
  background-position: -1509px 0;
}

#e_49 {
  background-position: -1541px 0;
}

#e_50 {
  background-position: -1573px 0;
}

#e_51 {
  background-position: -1605px 0;
}

#e_52 {
  background-position: -1637px 0;
}

#e_53 {
  background-position: -1669px 0;
}

#e_54 {
  background-position: -1701px 0;
}

#e_55 {
  background-position: -1733px 0;
}

#e_56 {
  background-position: -1765px 0;
}

#e_57 {
  background-position: -1797px 0;
}

#e_58 {
  background-position: -1829px 0;
}

#e_59 {
  background-position: -1861px 0;
}

#e_60 {
  background-position: -1893px 0;
}

#e_61 {
  background-position: -1925px 0;
}

#e_62 {
  background-position: -1957px 0;
}

#e_63 {
  background-position: -1989px 0;
}

#e_64 {
  background-position: -2021px 0;
}

#e_65 {
  background-position: -2053px 0;
}

#e_66 {
  background-position: -2085px 0;
}

#e_67 {
  background-position: -2117px 0;
}

#e_68 {
  background-position: -2149px 0;
}

#e_69 {
  background-position: -2181px 0;
}

#e_70 {
  background-position: -2213px 0;
}

#e_71 {
  background-position: -2245px 0;
}

#e_72 {
  background-position: -2277px 0;
}

#e_73 {
  background-position: -2309px 0;
}

#e_74 {
  background-position: -2341px 0;
}

#e_75 {
  background-position: -2373px 0;
}

#e_76 {
  background-position: -2405px 0;
}

#e_77 {
  background-position: -2437px 0;
}

#e_78 {
  background-position: -2469px 0;
}

#e_79 {
  background-position: -2501px 0;
}

#e_80 {
  background-position: -2533px 0;
}

#e_81 {
  background-position: -2565px 0;
}

#e_82 {
  background-position: -2597px 0;
}

#e_83 {
  background-position: -2629px 0;
}

#e_84 {
  background-position: -2661px 0;
}

#e_85 {
  background-position: -2693px 0;
}

#e_86 {
  background-position: -2725px 0;
}

#e_87 {
  background-position: -2757px 0;
}

#e_88 {
  background-position: -2789px 0;
}

#e_89 {
  background-position: -2821px 0;
}

#e_90 {
  background-position: -2853px 0;
}

#e_91 {
  background-position: -2885px 0;
}

#e_92 {
  background-position: -2917px 0;
}

#e_93 {
  background-position: -2949px 0;
}

#e_94 {
  background-position: -2981px 0;
}

#e_95 {
  background-position: -3013px 0;
}

#e_96 {
  background-position: -3045px 0;
}

#e_97 {
  background-position: -3077px 0;
}

#e_98 {
  background-position: -3109px 0;
}

#e_99 {
  background-position: -3141px 0;
}

#e_100 {
  background-position: -3173px 0;
}

#e_101 {
  background-position: -3205px 0;
}

#e_102 {
  background-position: -3237px 0;
}

#e_103 {
  background-position: -3269px 0;
}

#e_104 {
  background-position: -3301px 0;
}

#e_105 {
  background-position: -3333px 0;
}

#e_106 {
  background-position: -3365px 0;
}

#e_107 {
  background-position: -3397px 0;
}

#e_108 {
  background-position: -3429px 0;
}

#e_109 {
  background-position: -3461px 0;
}

#e_110 {
  background-position: -3493px 0;
}

#e_111 {
  background-position: -3525px 0;
}

#e_112 {
  background-position: -3557px 0;
}

#e_113 {
  background-position: -3589px 0;
}

#e_114 {
  background-position: -3621px 0;
}

#e_115 {
  background-position: -3653px 0;
}

#e_116 {
  background-position: -3685px 0;
}

#e_117 {
  background-position: -3717px 0;
}

#e_118 {
  background-position: -3749px 0;
}

#e_119 {
  background-position: -3781px 0;
}

#e_120 {
  background-position: -3813px 0;
}

#e_121 {
  background-position: -3845px 0;
}

#e_122 {
  background-position: -3877px 0;
}

#e_123 {
  background-position: -3909px 0;
}

#e_124 {
  background-position: -3941px 0;
}

#e_125 {
  background-position: -3973px 0;
}

#e_126 {
  background-position: -4005px 0;
}

#e_127 {
  background-position: -4037px 0;
}

#e_128 {
  background-position: -4069px 0;
}

#e_129 {
  background-position: -4101px 0;
}

#e_130 {
  background-position: -4133px 0;
}

#e_131 {
  background-position: -4165px 0;
}

#e_132 {
  background-position: -4197px 0;
}

#e_133 {
  background-position: -4229px 0;
}

#e_134 {
  background-position: -4261px 0;
}

#e_135 {
  background-position: -4293px 0;
}

#e_136 {
  background-position: -4325px 0;
}

#e_137 {
  background-position: -4357px 0;
}

#e_138 {
  background-position: -4389px 0;
}

#e_139 {
  background-position: -4421px 0;
}

#e_140 {
  background-position: -4453px 0;
}

#e_141 {
  background-position: -4485px 0;
}

#e_142 {
  background-position: -4517px 0;
}

#e_143 {
  background-position: -4549px 0;
}

#e_144 {
  background-position: -4581px 0;
}

#e_145 {
  background-position: -4613px 0;
}

#e_146 {
  background-position: -4645px 0;
}

#e_147 {
  background-position: -4677px 0;
}

#e_148 {
  background-position: -4709px 0;
}

#e_149 {
  background-position: -4741px 0;
}

#e_150 {
  background-position: -4773px 0;
}

#e_151 {
  background-position: -4805px 0;
}

#e_152 {
  background-position: -4837px 0;
}

#e_153 {
  background-position: -4869px 0;
}

#e_154 {
  background-position: -4901px 0;
}

#e_155 {
  background-position: -4933px 0;
}

#e_156 {
  background-position: -4965px 0;
}

#e_157 {
  background-position: -4997px 0;
}

#e_158 {
  background-position: -5029px 0;
}

#e_159 {
  background-position: -5061px 0;
}

#e_160 {
  background-position: -5093px 0;
}

#e_161 {
  background-position: -5125px 0;
}

#e_162 {
  background-position: -5157px 0;
}

#e_163 {
  background-position: -5189px 0;
}

#e_164 {
  background-position: -5221px 0;
}

#e_165 {
  background-position: -5253px 0;
}

#e_166 {
  background-position: -5285px 0;
}

#e_167 {
  background-position: -5317px 0;
}

#e_168 {
  background-position: -5349px 0;
}

#e_169 {
  background-position: -5381px 0;
}

#e_170 {
  background-position: -5413px 0;
}

#e_171 {
  background-position: -5445px 0;
}

#e_172 {
  background-position: -5477px 0;
}

#e_173 {
  background-position: -5509px 0;
}

#e_174 {
  background-position: -5541px 0;
}

#e_175 {
  background-position: -5573px 0;
}

#e_176 {
  background-position: -5605px 0;
}

#e_177 {
  background-position: -5637px 0;
}

#e_178 {
  background-position: -5669px 0;
}

#e_179 {
  background-position: -5701px 0;
}

#e_180 {
  background-position: -5733px 0;
}

#e_181 {
  background-position: -5765px 0;
}

#e_182 {
  background-position: -5797px 0;
}

#e_183 {
  background-position: -5829px 0;
}

#e_184 {
  background-position: -5861px 0;
}

:global(.e_finnadie) {
  background-position: -5893px 0;
}

#e_186 {
  background-position: -5925px 0;
}

:global(.e_godmode) {
  background-position: -5957px 0;
}

#e_188 {
  background-position: -5989px 0;
}

#e_189 {
  background-position: -6021px 0;
}

#e_190 {
  background-position: -6053px 0;
}

#e_191 {
  background-position: -6085px 0;
}

#e_192 {
  background-position: -6117px 0;
}

#e_193 {
  background-position: -6149px 0;
}

#e_194 {
  background-position: -6181px 0;
}

#e_195 {
  background-position: -6213px 0;
}

#e_196 {
  background-position: -6245px 0;
}

#e_197 {
  background-position: -6277px 0;
}

#e_198 {
  background-position: -6309px 0;
}

#e_199 {
  background-position: -6341px 0;
}

#e_200 {
  background-position: -6373px 0;
}

#e_201 {
  background-position: -6405px 0;
}

#e_202 {
  background-position: -6437px 0;
}

#e_203 {
  background-position: -6469px 0;
}

#e_204 {
  background-position: -6501px 0;
}

#e_205 {
  background-position: -6533px 0;
}

#e_206 {
  background-position: -6565px 0;
}

#e_207 {
  background-position: -6597px 0;
}

#e_208 {
  background-position: -6629px 0;
}

#e_209 {
  background-position: -6661px 0;
}

#e_210 {
  background-position: -6693px 0;
}

#e_211 {
  background-position: -6725px 0;
}

#e_212 {
  background-position: -6757px 0;
}

#e_213 {
  background-position: -6789px 0;
}

#e_214 {
  background-position: -6821px 0;
}

#e_215 {
  background-position: -6853px 0;
}

#e_216 {
  background-position: -6885px 0;
}

#e_217 {
  background-position: -6917px 0;
}

#e_218 {
  background-position: -6949px 0;
}

#e_219 {
  background-position: -6981px 0;
}

#e_220 {
  background-position: -7013px 0;
}

#e_221 {
  background-position: -7045px 0;
}

#e_222 {
  background-position: -7077px 0;
}

#e_223 {
  background-position: -7109px 0;
}

#e_224 {
  background-position: -7141px 0;
}

#e_225 {
  background-position: -7173px 0;
}

#e_226 {
  background-position: -7205px 0;
}

#e_227 {
  background-position: -7237px 0;
}

#e_228 {
  background-position: -7269px 0;
}

#e_229 {
  background-position: -7301px 0;
}

#e_230 {
  background-position: -7333px 0;
}

#e_231 {
  background-position: -7365px 0;
}

#e_232 {
  background-position: -7397px 0;
}

#e_233 {
  background-position: -7429px 0;
}

:global(.e_bug) {
  background-position: -7461px 0;
}

#e_235 {
  background-position: -7493px 0;
}

#e_236 {
  background-position: -7525px 0;
}

#e_237 {
  background-position: -7557px 0;
}

#e_238 {
  background-position: -7589px 0;
}

#e_239 {
  background-position: -7621px 0;
}

#e_240 {
  background-position: -7653px 0;
}

#e_241 {
  background-position: -7685px 0;
}

#e_242 {
  background-position: -7717px 0;
}

#e_243 {
  background-position: -7749px 0;
}

#e_244 {
  background-position: -7781px 0;
}

#e_245 {
  background-position: -7813px 0;
}

#e_246 {
  background-position: -7845px 0;
}

#e_247 {
  background-position: -7877px 0;
}

#e_248 {
  background-position: -7909px 0;
}

#e_249 {
  background-position: -7941px 0;
}

#e_250 {
  background-position: -7973px 0;
}

#e_251 {
  background-position: -8005px 0;
}

#e_252 {
  background-position: -8037px 0;
}

#e_253 {
  background-position: -8069px 0;
}

#e_254 {
  background-position: -8101px 0;
}

#e_255 {
  background-position: -8133px 0;
}

#e_256 {
  background-position: -8165px 0;
}

#e_257 {
  background-position: -8197px 0;
}

#e_258 {
  background-position: -8229px 0;
}

#e_259 {
  background-position: -8261px 0;
}

#e_260 {
  background-position: -8293px 0;
}

#e_261 {
  background-position: -8325px 0;
}

#e_262 {
  background-position: -8357px 0;
}

#e_263 {
  background-position: -8389px 0;
}

#e_264 {
  background-position: -8421px 0;
}

#e_265 {
  background-position: -8453px 0;
}

#e_266 {
  background-position: -8485px 0;
}

#e_267 {
  background-position: -8517px 0;
}

#e_268 {
  background-position: -8549px 0;
}

#e_269 {
  background-position: -8581px 0;
}

#e_270 {
  background-position: -8613px 0;
}

#e_271 {
  background-position: -8645px 0;
}

#e_272 {
  background-position: -8677px 0;
}

#e_273 {
  background-position: -8709px 0;
}

#e_274 {
  background-position: -8741px 0;
}

#e_275 {
  background-position: -8773px 0;
}

#e_276 {
  background-position: -8805px 0;
}

#e_277 {
  background-position: -8837px 0;
}

#e_278 {
  background-position: -8869px 0;
}

#e_279 {
  background-position: -8901px 0;
}

#e_280 {
  background-position: -8933px 0;
}

#e_281 {
  background-position: -8965px 0;
}

#e_282 {
  background-position: -8997px 0;
}

#e_283 {
  background-position: -9029px 0;
}

#e_284 {
  background-position: -9061px 0;
}

#e_285 {
  background-position: -9093px 0;
}

#e_286 {
  background-position: -9125px 0;
}

#e_287 {
  background-position: -9157px 0;
}

#e_288 {
  background-position: -9189px 0;
}

#e_289 {
  background-position: -9221px 0;
}

#e_290 {
  background-position: -9253px 0;
}

#e_291 {
  background-position: -9285px 0;
}

#e_292 {
  background-position: -9317px 0;
}

#e_293 {
  background-position: -9349px 0;
}

#e_294 {
  background-position: -9381px 0;
}

#e_295 {
  background-position: -9413px 0;
}

#e_296 {
  background-position: -9445px 0;
}

#e_297 {
  background-position: -9477px 0;
}

#e_298 {
  background-position: -9509px 0;
}

#e_299 {
  background-position: -9541px 0;
}

#e_300 {
  background-position: -9573px 0;
}

#e_301 {
  background-position: -9605px 0;
}

#e_302 {
  background-position: -9637px 0;
}

#e_303 {
  background-position: -9669px 0;
}

#e_304 {
  background-position: -9701px 0;
}

#e_305 {
  background-position: -9733px 0;
}

#e_306 {
  background-position: -9765px 0;
}

#e_307 {
  background-position: -9797px 0;
}

#e_308 {
  background-position: -9829px 0;
}

#e_309 {
  background-position: -9861px 0;
}

#e_310 {
  background-position: -9893px 0;
}

#e_311 {
  background-position: -9925px 0;
}

#e_312 {
  background-position: -9957px 0;
}

#e_313 {
  background-position: -9989px 0;
}

#e_314 {
  background-position: -10021px 0;
}

#e_315 {
  background-position: -10053px 0;
}

#e_316 {
  background-position: -10085px 0;
}

#e_317 {
  background-position: -10117px 0;
}

#e_318 {
  background-position: -10149px 0;
}

#e_319 {
  background-position: -10181px 0;
}

#e_320 {
  background-position: -10213px 0;
}

#e_321 {
  background-position: -10245px 0;
}

#e_322 {
  background-position: -10277px 0;
}

#e_323 {
  background-position: -10309px 0;
}

#e_324 {
  background-position: -10341px 0;
}

#e_325 {
  background-position: -10373px 0;
}

#e_326 {
  background-position: -10405px 0;
}

#e_327 {
  background-position: -10437px 0;
}

#e_328 {
  background-position: -10469px 0;
}

#e_329 {
  background-position: -10501px 0;
}

#e_330 {
  background-position: -10533px 0;
}

#e_331 {
  background-position: -10565px 0;
}

#e_332 {
  background-position: -10597px 0;
}

#e_333 {
  background-position: -10629px 0;
}

#e_334 {
  background-position: -10661px 0;
}

#e_335 {
  background-position: -10693px 0;
}

#e_336 {
  background-position: -10725px 0;
}

#e_337 {
  background-position: -10757px 0;
}

#e_338 {
  background-position: -10789px 0;
}

#e_339 {
  background-position: -10821px 0;
}

#e_340 {
  background-position: -10853px 0;
}

#e_341 {
  background-position: -10885px 0;
}

#e_342 {
  background-position: -10917px 0;
}

#e_343 {
  background-position: -10949px 0;
}

#e_344 {
  background-position: -10981px 0;
}

#e_345 {
  background-position: -11013px 0;
}

#e_346 {
  background-position: -11045px 0;
}

#e_347 {
  background-position: -11077px 0;
}

#e_348 {
  background-position: -11109px 0;
}

#e_349 {
  background-position: -11141px 0;
}

#e_350 {
  background-position: -11173px 0;
}

#e_351 {
  background-position: -11205px 0;
}

#e_352 {
  background-position: -11237px 0;
}

#e_353 {
  background-position: -11269px 0;
}

#e_354 {
  background-position: -11301px 0;
}

#e_355 {
  background-position: -11333px 0;
}

#e_356 {
  background-position: -11365px 0;
}

#e_357 {
  background-position: -11397px 0;
}

#e_358 {
  background-position: -11429px 0;
}

#e_359 {
  background-position: -11461px 0;
}

#e_360 {
  background-position: -11493px 0;
}

#e_361 {
  background-position: -11525px 0;
}

#e_362 {
  background-position: -11557px 0;
}

#e_363 {
  background-position: -11589px 0;
}

#e_364 {
  background-position: -11621px 0;
}

#e_365 {
  background-position: -11653px 0;
}

#e_366 {
  background-position: -11685px 0;
}

#e_367 {
  background-position: -11717px 0;
}

#e_368 {
  background-position: -11749px 0;
}

#e_369 {
  background-position: -11781px 0;
}

#e_370 {
  background-position: -11813px 0;
}

#e_371 {
  background-position: -11845px 0;
}

#e_372 {
  background-position: -11877px 0;
}

#e_373 {
  background-position: -11909px 0;
}

#e_374 {
  background-position: -11941px 0;
}

#e_375 {
  background-position: -11973px 0;
}

#e_376 {
  background-position: -12005px 0;
}

#e_377 {
  background-position: -12037px 0;
}

#e_378 {
  background-position: -12069px 0;
}

#e_379 {
  background-position: -12101px 0;
}

#e_380 {
  background-position: -12133px 0;
}

#e_381 {
  background-position: -12165px 0;
}

#e_382 {
  background-position: -12197px 0;
}

#e_383 {
  background-position: -12229px 0;
}

#e_384 {
  background-position: -12261px 0;
}

#e_385 {
  background-position: -12293px 0;
}

#e_386 {
  background-position: -12325px 0;
}

#e_387 {
  background-position: -12357px 0;
}

#e_388 {
  background-position: -12389px 0;
}

#e_389 {
  background-position: -12421px 0;
}

#e_390 {
  background-position: -12453px 0;
}

#e_391 {
  background-position: -12485px 0;
}

#e_392 {
  background-position: -12517px 0;
}

#e_393 {
  background-position: -12549px 0;
}

#e_394 {
  background-position: -12581px 0;
}

#e_395 {
  background-position: -12613px 0;
}

#e_396 {
  background-position: -12645px 0;
}

#e_397 {
  background-position: -12677px 0;
}

#e_398 {
  background-position: -12709px 0;
}

#e_399 {
  background-position: -12741px 0;
}

#e_400 {
  background-position: -12773px 0;
}

#e_401 {
  background-position: -12805px 0;
}

#e_402 {
  background-position: -12837px 0;
}

#e_403 {
  background-position: -12869px 0;
}

#e_404 {
  background-position: -12901px 0;
}

#e_405 {
  background-position: -12933px 0;
}

#e_406 {
  background-position: -12965px 0;
}

#e_407 {
  background-position: -12997px 0;
}

#e_408 {
  background-position: -13029px 0;
}

#e_409 {
  background-position: -13061px 0;
}

#e_410 {
  background-position: -13093px 0;
}

#e_411 {
  background-position: -13125px 0;
}

#e_412 {
  background-position: -13157px 0;
}

#e_413 {
  background-position: -13189px 0;
}

#e_414 {
  background-position: -13221px 0;
}

#e_415 {
  background-position: -13253px 0;
}

#e_416 {
  background-position: -13285px 0;
}

#e_417 {
  background-position: -13317px 0;
}

#e_418 {
  background-position: -13349px 0;
}

#e_419 {
  background-position: -13381px 0;
}

#e_420 {
  background-position: -13413px 0;
}

#e_421 {
  background-position: -13445px 0;
}

#e_422 {
  background-position: -13477px 0;
}

:global(.e_pushpin) {
  background-position: -13509px 0;
}

#e_424 {
  background-position: -13541px 0;
}

#e_425 {
  background-position: -13573px 0;
}

#e_426 {
  background-position: -13605px 0;
}

#e_427 {
  background-position: -13637px 0;
}

#e_428 {
  background-position: -13669px 0;
}

#e_429 {
  background-position: -13701px 0;
}

#e_430 {
  background-position: -13733px 0;
}

#e_431 {
  background-position: -13765px 0;
}

#e_432 {
  background-position: -13797px 0;
}

#e_433 {
  background-position: -13829px 0;
}

#e_434 {
  background-position: -13861px 0;
}

#e_435 {
  background-position: -13893px 0;
}

#e_436 {
  background-position: -13925px 0;
}

#e_437 {
  background-position: -13957px 0;
}

#e_438 {
  background-position: -13989px 0;
}

#e_439 {
  background-position: -14021px 0;
}

#e_440 {
  background-position: -14053px 0;
}

#e_441 {
  background-position: -14085px 0;
}

#e_442 {
  background-position: -14117px 0;
}

#e_443 {
  background-position: -14149px 0;
}

#e_444 {
  background-position: -14181px 0;
}

#e_445 {
  background-position: -14213px 0;
}

#e_446 {
  background-position: -14245px 0;
}

#e_447 {
  background-position: -14277px 0;
}

#e_448 {
  background-position: -14309px 0;
}

#e_449 {
  background-position: -14341px 0;
}

#e_450 {
  background-position: -14373px 0;
}

#e_451 {
  background-position: -14405px 0;
}

#e_452 {
  background-position: -14437px 0;
}

#e_453 {
  background-position: -14469px 0;
}

#e_454 {
  background-position: -14501px 0;
}

#e_455 {
  background-position: -14533px 0;
}

#e_456 {
  background-position: -14565px 0;
}

#e_457 {
  background-position: -14597px 0;
}

#e_458 {
  background-position: -14629px 0;
}

#e_459 {
  background-position: -14661px 0;
}

#e_460 {
  background-position: -14693px 0;
}

#e_461 {
  background-position: -14725px 0;
}

#e_462 {
  background-position: -14757px 0;
}

#e_463 {
  background-position: -14789px 0;
}

#e_464 {
  background-position: -14821px 0;
}

#e_465 {
  background-position: -14853px 0;
}

#e_466 {
  background-position: -14885px 0;
}

#e_467 {
  background-position: -14917px 0;
}

#e_468 {
  background-position: -14949px 0;
}

#e_469 {
  background-position: -14981px 0;
}

#e_470 {
  background-position: -15013px 0;
}

#e_471 {
  background-position: -15045px 0;
}

#e_472 {
  background-position: -15077px 0;
}

#e_473 {
  background-position: -15109px 0;
}

#e_474 {
  background-position: -15141px 0;
}

#e_475 {
  background-position: -15173px 0;
}

#e_476 {
  background-position: -15205px 0;
}

#e_477 {
  background-position: -15237px 0;
}

#e_478 {
  background-position: -15269px 0;
}

#e_479 {
  background-position: -15301px 0;
}

#e_480 {
  background-position: -15333px 0;
}

#e_481 {
  background-position: -15365px 0;
}

#e_482 {
  background-position: -15397px 0;
}

#e_483 {
  background-position: -15429px 0;
}

#e_484 {
  background-position: -15461px 0;
}

#e_485 {
  background-position: -15493px 0;
}

#e_486 {
  background-position: -15525px 0;
}

#e_487 {
  background-position: -15557px 0;
}

#e_488 {
  background-position: -15589px 0;
}

#e_489 {
  background-position: -15621px 0;
}

#e_490 {
  background-position: -15653px 0;
}

#e_491 {
  background-position: -15685px 0;
}

#e_492 {
  background-position: -15717px 0;
}

#e_493 {
  background-position: -15749px 0;
}

#e_494 {
  background-position: -15781px 0;
}

#e_495 {
  background-position: -15813px 0;
}

#e_496 {
  background-position: -15845px 0;
}

#e_497 {
  background-position: -15877px 0;
}

#e_498 {
  background-position: -15909px 0;
}

#e_499 {
  background-position: -15941px 0;
}

#e_500 {
  background-position: -15973px 0;
}

#e_501 {
  background-position: -16005px 0;
}

#e_502 {
  background-position: -16037px 0;
}

#e_503 {
  background-position: -16069px 0;
}

#e_504 {
  background-position: -16101px 0;
}

#e_505 {
  background-position: -16133px 0;
}

#e_506 {
  background-position: -16165px 0;
}

#e_507 {
  background-position: -16197px 0;
}

#e_508 {
  background-position: -16229px 0;
}

#e_509 {
  background-position: -16261px 0;
}

#e_510 {
  background-position: -16293px 0;
}

#e_511 {
  background-position: -16325px 0;
}

#e_512 {
  background-position: -16357px 0;
}

#e_513 {
  background-position: -16389px 0;
}

#e_514 {
  background-position: -16421px 0;
}

#e_515 {
  background-position: -16453px 0;
}

#e_516 {
  background-position: -16485px 0;
}

#e_517 {
  background-position: -16517px 0;
}

#e_518 {
  background-position: -16549px 0;
}

#e_519 {
  background-position: -16581px 0;
}

#e_520 {
  background-position: -16613px 0;
}

#e_521 {
  background-position: -16645px 0;
}

#e_522 {
  background-position: -16677px 0;
}

#e_523 {
  background-position: -16709px 0;
}

#e_524 {
  background-position: -16741px 0;
}

#e_525 {
  background-position: -16773px 0;
}

#e_526 {
  background-position: -16805px 0;
}

#e_527 {
  background-position: -16837px 0;
}

#e_528 {
  background-position: -16869px 0;
}

#e_529 {
  background-position: -16901px 0;
}

#e_530 {
  background-position: -16933px 0;
}

#e_531 {
  background-position: -16965px 0;
}

#e_532 {
  background-position: -16997px 0;
}

#e_533 {
  background-position: -17029px 0;
}

#e_534 {
  background-position: -17061px 0;
}

#e_535 {
  background-position: -17093px 0;
}

#e_536 {
  background-position: -17125px 0;
}

#e_537 {
  background-position: -17157px 0;
}

#e_538 {
  background-position: -17189px 0;
}

#e_539 {
  background-position: -17221px 0;
}

#e_540 {
  background-position: -17253px 0;
}

#e_541 {
  background-position: -17285px 0;
}

#e_542 {
  background-position: -17317px 0;
}

#e_543 {
  background-position: -17349px 0;
}

#e_544 {
  background-position: -17381px 0;
}

#e_545 {
  background-position: -17413px 0;
}

#e_546 {
  background-position: -17445px 0;
}

#e_547 {
  background-position: -17477px 0;
}

#e_548 {
  background-position: -17509px 0;
}

#e_549 {
  background-position: -17541px 0;
}

#e_550 {
  background-position: -17573px 0;
}

#e_551 {
  background-position: -17605px 0;
}

#e_552 {
  background-position: -17637px 0;
}

#e_553 {
  background-position: -17669px 0;
}

#e_554 {
  background-position: -17701px 0;
}

#e_555 {
  background-position: -17733px 0;
}

#e_556 {
  background-position: -17765px 0;
}

#e_557 {
  background-position: -17797px 0;
}

#e_558 {
  background-position: -17829px 0;
}

#e_559 {
  background-position: -17861px 0;
}

#e_560 {
  background-position: -17893px 0;
}

#e_561 {
  background-position: -17925px 0;
}

#e_562 {
  background-position: -17957px 0;
}

#e_563 {
  background-position: -17989px 0;
}

#e_564 {
  background-position: -18021px 0;
}

#e_565 {
  background-position: -18053px 0;
}

#e_566 {
  background-position: -18085px 0;
}

#e_567 {
  background-position: -18117px 0;
}

#e_568 {
  background-position: -18149px 0;
}

#e_569 {
  background-position: -18181px 0;
}

#e_570 {
  background-position: -18213px 0;
}

#e_571 {
  background-position: -18245px 0;
}

#e_572 {
  background-position: -18277px 0;
}

#e_573 {
  background-position: -18309px 0;
}

#e_574 {
  background-position: -18341px 0;
}

#e_575 {
  background-position: -18373px 0;
}

#e_576 {
  background-position: -18405px 0;
}

#e_577 {
  background-position: -18437px 0;
}

#e_578 {
  background-position: -18469px 0;
}

#e_579 {
  background-position: -18501px 0;
}

#e_580 {
  background-position: -18533px 0;
}

#e_581 {
  background-position: -18565px 0;
}

#e_582 {
  background-position: -18597px 0;
}

#e_583 {
  background-position: -18629px 0;
}

#e_584 {
  background-position: -18661px 0;
}

#e_585 {
  background-position: -18693px 0;
}

#e_586 {
  background-position: -18725px 0;
}

#e_587 {
  background-position: -18757px 0;
}

#e_588 {
  background-position: -18789px 0;
}

#e_589 {
  background-position: -18821px 0;
}

#e_590 {
  background-position: -18853px 0;
}

#e_591 {
  background-position: -18885px 0;
}

#e_592 {
  background-position: -18917px 0;
}

#e_593 {
  background-position: -18949px 0;
}

#e_594 {
  background-position: -18981px 0;
}

#e_595 {
  background-position: -19013px 0;
}

#e_596 {
  background-position: -19045px 0;
}

#e_597 {
  background-position: -19077px 0;
}

#e_598 {
  background-position: -19109px 0;
}

#e_599 {
  background-position: -19141px 0;
}

#e_600 {
  background-position: -19173px 0;
}

#e_601 {
  background-position: -19205px 0;
}

#e_602 {
  background-position: -19237px 0;
}

#e_603 {
  background-position: -19269px 0;
}

#e_604 {
  background-position: -19301px 0;
}

#e_605 {
  background-position: -19333px 0;
}

#e_606 {
  background-position: -19365px 0;
}

#e_607 {
  background-position: -19397px 0;
}

#e_608 {
  background-position: -19429px 0;
}

#e_609 {
  background-position: -19461px 0;
}

#e_610 {
  background-position: -19493px 0;
}

#e_611 {
  background-position: -19525px 0;
}

#e_612 {
  background-position: -19557px 0;
}

#e_613 {
  background-position: -19589px 0;
}

#e_614 {
  background-position: -19621px 0;
}

#e_615 {
  background-position: -19653px 0;
}

#e_616 {
  background-position: -19685px 0;
}

#e_617 {
  background-position: -19717px 0;
}

#e_618 {
  background-position: -19749px 0;
}

#e_619 {
  background-position: -19781px 0;
}

#e_620 {
  background-position: -19813px 0;
}

#e_621 {
  background-position: -19845px 0;
}

#e_622 {
  background-position: -19877px 0;
}

#e_623 {
  background-position: -19909px 0;
}

#e_624 {
  background-position: -19941px 0;
}

#e_625 {
  background-position: -19973px 0;
}

#e_626 {
  background-position: -20005px 0;
}

#e_627 {
  background-position: -20037px 0;
}

#e_628 {
  background-position: -20069px 0;
}

#e_629 {
  background-position: -20101px 0;
}

#e_630 {
  background-position: -20133px 0;
}

#e_631 {
  background-position: -20165px 0;
}

#e_632 {
  background-position: -20197px 0;
}

#e_633 {
  background-position: -20229px 0;
}

#e_634 {
  background-position: -20261px 0;
}

#e_635 {
  background-position: -20293px 0;
}

#e_636 {
  background-position: -20325px 0;
}

#e_637 {
  background-position: -20357px 0;
}

#e_638 {
  background-position: -20389px 0;
}

#e_639 {
  background-position: -20421px 0;
}

#e_640 {
  background-position: -20453px 0;
}

#e_641 {
  background-position: -20485px 0;
}

#e_642 {
  background-position: -20517px 0;
}

#e_643 {
  background-position: -20549px 0;
}

#e_644 {
  background-position: -20581px 0;
}

#e_645 {
  background-position: -20613px 0;
}

#e_646 {
  background-position: -20645px 0;
}

#e_647 {
  background-position: -20677px 0;
}

#e_648 {
  background-position: -20709px 0;
}

#e_649 {
  background-position: -20741px 0;
}

#e_650 {
  background-position: -20773px 0;
}

#e_651 {
  background-position: -20805px 0;
}

#e_652 {
  background-position: -20837px 0;
}

#e_653 {
  background-position: -20869px 0;
}

#e_654 {
  background-position: -20901px 0;
}

#e_655 {
  background-position: -20933px 0;
}

#e_656 {
  background-position: -20965px 0;
}

#e_657 {
  background-position: -20997px 0;
}

#e_658 {
  background-position: -21029px 0;
}

#e_659 {
  background-position: -21061px 0;
}

#e_660 {
  background-position: -21093px 0;
}

#e_661 {
  background-position: -21125px 0;
}

#e_662 {
  background-position: -21157px 0;
}

#e_663 {
  background-position: -21189px 0;
}

#e_664 {
  background-position: -21221px 0;
}

#e_665 {
  background-position: -21253px 0;
}

#e_666 {
  background-position: -21285px 0;
}

#e_667 {
  background-position: -21317px 0;
}

#e_668 {
  background-position: -21349px 0;
}

#e_669 {
  background-position: -21381px 0;
}

#e_670 {
  background-position: -21413px 0;
}

#e_671 {
  background-position: -21445px 0;
}

#e_672 {
  background-position: -21477px 0;
}

#e_673 {
  background-position: -21509px 0;
}

#e_674 {
  background-position: -21541px 0;
}

#e_675 {
  background-position: -21573px 0;
}

#e_676 {
  background-position: -21605px 0;
}

#e_677 {
  background-position: -21637px 0;
}

#e_678 {
  background-position: -21669px 0;
}

#e_679 {
  background-position: -21701px 0;
}

#e_680 {
  background-position: -21733px 0;
}

#e_681 {
  background-position: -21765px 0;
}

#e_682 {
  background-position: -21797px 0;
}

#e_683 {
  background-position: -21829px 0;
}

#e_684 {
  background-position: -21861px 0;
}

#e_685 {
  background-position: -21893px 0;
}

#e_686 {
  background-position: -21925px 0;
}

#e_687 {
  background-position: -21957px 0;
}

#e_688 {
  background-position: -21989px 0;
}

#e_689 {
  background-position: -22021px 0;
}

#e_690 {
  background-position: -22053px 0;
}

#e_691 {
  background-position: -22085px 0;
}

#e_692 {
  background-position: -22117px 0;
}

#e_693 {
  background-position: -22149px 0;
}

#e_694 {
  background-position: -22181px 0;
}

#e_695 {
  background-position: -22213px 0;
}

#e_696 {
  background-position: -22245px 0;
}

#e_697 {
  background-position: -22277px 0;
}

#e_698 {
  background-position: -22309px 0;
}

#e_699 {
  background-position: -22341px 0;
}

#e_700 {
  background-position: -22373px 0;
}

#e_701 {
  background-position: -22405px 0;
}

#e_702 {
  background-position: -22437px 0;
}

#e_703 {
  background-position: -22469px 0;
}

#e_704 {
  background-position: -22501px 0;
}

#e_705 {
  background-position: -22533px 0;
}

#e_706 {
  background-position: -22565px 0;
}

#e_707 {
  background-position: -22597px 0;
}

#e_708 {
  background-position: -22629px 0;
}

#e_709 {
  background-position: -22661px 0;
}

#e_710 {
  background-position: -22693px 0;
}

#e_711 {
  background-position: -22725px 0;
}

#e_712 {
  background-position: -22757px 0;
}

#e_713 {
  background-position: -22789px 0;
}

#e_714 {
  background-position: -22821px 0;
}

#e_715 {
  background-position: -22853px 0;
}

#e_716 {
  background-position: -22885px 0;
}

#e_717 {
  background-position: -22917px 0;
}

#e_718 {
  background-position: -22949px 0;
}

#e_719 {
  background-position: -22981px 0;
}

#e_720 {
  background-position: -23013px 0;
}

#e_721 {
  background-position: -23045px 0;
}

#e_722 {
  background-position: -23077px 0;
}

#e_723 {
  background-position: -23109px 0;
}

#e_724 {
  background-position: -23141px 0;
}

#e_725 {
  background-position: -23173px 0;
}

#e_726 {
  background-position: -23205px 0;
}

#e_727 {
  background-position: -23237px 0;
}

#e_728 {
  background-position: -23269px 0;
}

#e_729 {
  background-position: -23301px 0;
}

#e_730 {
  background-position: -23333px 0;
}

#e_731 {
  background-position: -23365px 0;
}

#e_732 {
  background-position: -23397px 0;
}

#e_733 {
  background-position: -23429px 0;
}

#e_734 {
  background-position: -23461px 0;
}

#e_735 {
  background-position: -23493px 0;
}

#e_736 {
  background-position: -23525px 0;
}

#e_737 {
  background-position: -23557px 0;
}

#e_738 {
  background-position: -23589px 0;
}

#e_739 {
  background-position: -23621px 0;
}

#e_740 {
  background-position: -23653px 0;
}

#e_741 {
  background-position: -23685px 0;
}

#e_742 {
  background-position: -23717px 0;
}

#e_743 {
  background-position: -23749px 0;
}

#e_744 {
  background-position: -23781px 0;
}

#e_745 {
  background-position: -23813px 0;
}

#e_746 {
  background-position: -23845px 0;
}

#e_747 {
  background-position: -23877px 0;
}

#e_748 {
  background-position: -23909px 0;
}

#e_749 {
  background-position: -23941px 0;
}

#e_750 {
  background-position: -23973px 0;
}

#e_751 {
  background-position: -24005px 0;
}

#e_752 {
  background-position: -24037px 0;
}

#e_753 {
  background-position: -24069px 0;
}

#e_754 {
  background-position: -24101px 0;
}

#e_755 {
  background-position: -24133px 0;
}

#e_756 {
  background-position: -24165px 0;
}

#e_757 {
  background-position: -24197px 0;
}

#e_758 {
  background-position: -24229px 0;
}

#e_759 {
  background-position: -24261px 0;
}

#e_760 {
  background-position: -24293px 0;
}

#e_761 {
  background-position: -24325px 0;
}

#e_762 {
  background-position: -24357px 0;
}

#e_763 {
  background-position: -24389px 0;
}

#e_764 {
  background-position: -24421px 0;
}

#e_765 {
  background-position: -24453px 0;
}

#e_766 {
  background-position: -24485px 0;
}

#e_767 {
  background-position: -24517px 0;
}

#e_768 {
  background-position: -24549px 0;
}

#e_769 {
  background-position: -24581px 0;
}

#e_770 {
  background-position: -24613px 0;
}

#e_771 {
  background-position: -24645px 0;
}

#e_772 {
  background-position: -24677px 0;
}

#e_773 {
  background-position: -24709px 0;
}

#e_774 {
  background-position: -24741px 0;
}

#e_775 {
  background-position: -24773px 0;
}

#e_776 {
  background-position: -24805px 0;
}

#e_777 {
  background-position: -24837px 0;
}

#e_778 {
  background-position: -24869px 0;
}

#e_779 {
  background-position: -24901px 0;
}

#e_780 {
  background-position: -24933px 0;
}

#e_781 {
  background-position: -24965px 0;
}

#e_782 {
  background-position: -24997px 0;
}

#e_783 {
  background-position: -25029px 0;
}

#e_784 {
  background-position: -25061px 0;
}

#e_785 {
  background-position: -25093px 0;
}

#e_786 {
  background-position: -25125px 0;
}

#e_787 {
  background-position: -25157px 0;
}

#e_788 {
  background-position: -25189px 0;
}

#e_789 {
  background-position: -25221px 0;
}

#e_790 {
  background-position: -25253px 0;
}

#e_791 {
  background-position: -25285px 0;
}

#e_792 {
  background-position: -25317px 0;
}

#e_793 {
  background-position: -25349px 0;
}

#e_794 {
  background-position: -25381px 0;
}

#e_795 {
  background-position: -25413px 0;
}

#e_796 {
  background-position: -25445px 0;
}

#e_797 {
  background-position: -25477px 0;
}

#e_798 {
  background-position: -25509px 0;
}

#e_799 {
  background-position: -25541px 0;
}

#e_800 {
  background-position: -25573px 0;
}

#e_801 {
  background-position: -25605px 0;
}

#e_802 {
  background-position: -25637px 0;
}

#e_803 {
  background-position: -25669px 0;
}

#e_804 {
  background-position: -25701px 0;
}

#e_805 {
  background-position: -25733px 0;
}

#e_806 {
  background-position: -25765px 0;
}

#e_807 {
  background-position: -25797px 0;
}

#e_808 {
  background-position: -25829px 0;
}

#e_809 {
  background-position: -25861px 0;
}

#e_810 {
  background-position: -25893px 0;
}

#e_811 {
  background-position: -25925px 0;
}

#e_812 {
  background-position: -25957px 0;
}

#e_813 {
  background-position: -25989px 0;
}

#e_814 {
  background-position: -26021px 0;
}

#e_815 {
  background-position: -26053px 0;
}

#e_816 {
  background-position: -26085px 0;
}

#e_817 {
  background-position: -26117px 0;
}

#e_818 {
  background-position: -26149px 0;
}

#e_819 {
  background-position: -26181px 0;
}

#e_820 {
  background-position: -26213px 0;
}

#e_821 {
  background-position: -26245px 0;
}

#e_822 {
  background-position: -26277px 0;
}

#e_823 {
  background-position: -26309px 0;
}

#e_824 {
  background-position: -26341px 0;
}

#e_825 {
  background-position: -26373px 0;
}

#e_826 {
  background-position: -26405px 0;
}

#e_827 {
  background-position: -26437px 0;
}

#e_828 {
  background-position: -26469px 0;
}

#e_829 {
  background-position: -26501px 0;
}

#e_830 {
  background-position: -26533px 0;
}

#e_831 {
  background-position: -26565px 0;
}

#e_832 {
  background-position: -26597px 0;
}

#e_833 {
  background-position: -26629px 0;
}

#e_834 {
  background-position: -26661px 0;
}

#e_835 {
  background-position: -26693px 0;
}

#e_836 {
  background-position: -26725px 0;
}

#e_837 {
  background-position: -26757px 0;
}

#e_838 {
  background-position: -26789px 0;
}

#e_839 {
  background-position: -26821px 0;
}

#e_840 {
  background-position: -26853px 0;
}

#e_841 {
  background-position: -26885px 0;
}

#e_842 {
  background-position: -26917px 0;
}

#e_843 {
  background-position: -26949px 0;
}

#e_844 {
  background-position: -26981px 0;
}

#e_845 {
  background-position: -27013px 0;
}

#e_846 {
  background-position: -27045px 0;
}

#e_847 {
  background-position: -27077px 0;
}

#e_848 {
  background-position: -27109px 0;
}

#e_849 {
  background-position: -27141px 0;
}

#e_850 {
  background-position: -27173px 0;
}

#e_851 {
  background-position: -27205px 0;
}

#e_852 {
  background-position: -27237px 0;
}

#e_853 {
  background-position: -27269px 0;
}

#e_854 {
  background-position: -27301px 0;
}

#e_855 {
  background-position: -27333px 0;
}

#e_856 {
  background-position: -27365px 0;
}

#e_857 {
  background-position: -27397px 0;
}

#e_858 {
  background-position: -27429px 0;
}

#e_859 {
  background-position: -27461px 0;
}

#e_860 {
  background-position: -27493px 0;
}

#e_861 {
  background-position: -27525px 0;
}

#e_862 {
  background-position: -27557px 0;
}

#e_863 {
  background-position: -27589px 0;
}

#e_864 {
  background-position: -27621px 0;
}

#e_865 {
  background-position: -27653px 0;
}

#e_866 {
  background-position: -27685px 0;
}

#e_867 {
  background-position: -27717px 0;
}

#e_868 {
  background-position: -27749px 0;
}

#e_869 {
  background-position: -27781px 0;
}

#e_870 {
  background-position: -27813px 0;
}

#e_871 {
  background-position: -27845px 0;
}

#e_872 {
  background-position: -27877px 0;
}

#e_873 {
  background-position: -27909px 0;
}

#e_874 {
  background-position: -27941px 0;
}

#e_875 {
  background-position: -27973px 0;
}

#e_876 {
  background-position: -28005px 0;
}

#e_877 {
  background-position: -28037px 0;
}
