.measure {
  display: grid;

  width: var(--workspace-width);
  height: 100%;

  margin: 0 auto;
  grid-template-columns: 1042px 1fr;
  grid-gap: 0;
}

.metrics-wrapper {
  position: relative;

  overflow: hidden;

  padding-left: var(--distancer-small);
}
