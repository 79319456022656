.title-wrapper {
  padding-bottom: var(--distancer-small);
}

.title {
  display: grid;

  padding: calc(var(--distancer) / 2);
  padding-left: calc(var(--distancer) / 2 - var(--distancer-small));

  cursor: pointer;
  grid-template-columns: 30px auto;
  grid-gap: calc(var(--distancer) / 2);
}

.title:hover {
  background-color: var(--color-item-hover);
}

.title--is-select,
.title--is-select.title:hover {
  background-color: var(--color-item-select);
}

.title--is-focus,
.title--is-focus.title:hover,
.title--is-focus.title--is-select.title,
.title--is-focus.title--is-select.title:hover {
  background-color: var(--color-item-focus);
}

.body {
  margin-top: 0;
  margin-bottom: var(--distancer-small);

  font-weight: bold;
}

.quotes-count {
  display: block;

  color: var(--color-gray-4);

  font-family: var(--font-family-mono);
  font-size: var(--font-size-small);
  font-weight: normal;
}

.score {
  display: block;

  width: var(--distancer);
  height: var(--distancer);

  margin: auto;
  padding: var(--distancer-small);

  text-align: center;
  vertical-align: middle;

  color: var(--color-white);
  border-radius: var(--radius-small);
  background-color: var(--color-n-blue);
}

.title--is-inbox .score {
  background-color: var(--color-black);
}

.title--is-inbox .quotes-count {
  color: var(--color-n-red);
}
