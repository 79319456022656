.yank {
  position: fixed;
  z-index: var(--z-index-highest);
  top: var(--distancer);
  right: var(--distancer);

  padding: calc(var(--distancer) / 2);

  border: 1px solid var(--color-black);
  border-radius: var(--radius-small);
  background-color: var(--color-white);
}
