:root {
  --input-height: 45px;
}

.select {
  height: var(--input-height);
  padding: 0 var(--distancer-small);

  border: 1px solid var(--color-dark-gray);
  border-radius: var(--radius-small);
  outline: none;
  background: var(--color-white);

  font-size: var(--font-size-medium);
  appearance: none;
}

.select:active,
.select:focus {
  border-color: var(--color-black);
  outline: none;
}

.is-error {
  border-color: var(--color-bad);
}
