/* stylelint-disable no-descending-specificity */

.hr {
  margin: calc(var(--distancer) * 3) 0;
}

@media (--phone), (--tablet) {
  .hr {
    margin: calc(var(--distancer) * 2) 0;
  }
}

/*
 * Hero
 */
.hero {
  position: relative;

  margin-bottom: calc(var(--distancer) * 2);
  padding: calc(var(--distancer) * 2);

  background-color: var(--color-n-gray-light);
}

.hero-content {
  width: var(--width-big);

  margin: 0 auto;
  padding: 0 var(--distancer);

  h1,
  h2 {
    text-align: center;

    line-height: 1.3;
  }
}

@media (--phone) {
  .hero {
    margin-bottom: var(--distancer);
    padding: var(--distancer) calc(var(--distancer) / 2);
  }
}

@media (--tablet) {
  .hero {
    margin-bottom: var(--distancer);
    padding: calc(var(--distancer) * 2);
  }
}

.hero h2 {
  margin-bottom: calc(var(--distancer) * 2);

  font-weight: normal;
}

@media (--phone) {
  .hero h1 {
    font-size: calc(var(--font-size-big) - 2px);
  }
}

@media (--tablet) {
  .hero h1 {
    font-size: var(--font-size-huge);
  }
}

.hero img {
  position: relative;

  display: block;

  width: 100%;
  margin: 0 auto calc(var(--distancer) * 2) auto;
}

@media (--phone), (--tablet) {
  .hero img {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
}

/**
 * 1 workspace, 3 tools
 */

.one-workspace,
.made-for {
  margin-bottom: calc(var(--distancer) * 2);

  font-family: var(--font-family-mono);
  font-size: 30px;
  font-weight: normal;
}

.essential-tools {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: calc(var(--distancer) * 2);

  margin-bottom: calc(var(--distancer) * 2);
}

.essential-tools > div {
  p:last-child {
    margin-bottom: 0;
  }

  code {
    display: block;

    margin-bottom: calc(var(--distancer));

    font-family: var(--font-family-mono);
  }

  code em {
    display: inline-block;

    width: 20px;
    height: 20px;

    text-align: center;

    color: var(--color-white);
    border-radius: var(--radius-small);
    background: var(--color-black);

    font-size: var(--font-size-small);
    font-weight: bold;
    font-style: normal;
    line-height: 20px;
  }
}

.github-button {
  display: inline-block;

  padding: var(--distancer-small) calc(var(--distancer) / 3);

  vertical-align: bottom;

  color: var(--color-black);
  border: 1px solid rgba(27, 31, 35, 0.35);

  border-radius: 3px;
  background-color: #EFF3F6;
  background-image: linear-gradient(180deg, #FAFBFC, #EFF3F6 90%);
  background-repeat: repeat-x;

  font-size: var(--font-size);
  line-height: var(--font-size);

  img {
    display: inline-block;

    width: 14px;
    height: 14px;
    margin-right: var(--distancer-small);
  }
}

.cli-img {
  display: block;

  width: 100%;
  margin-bottom: calc(var(--distancer) * 2);
}

/**
 *
 */
.section {
  width: var(--width-big);
  margin: 0 auto;
  padding: var(--distancer);

  composes: clearfix from "/core.ui/utils.css";
}

@media (--phone), (--tablet) {
  .section {
    width: 100%;
  }
}

.quote {
  padding-left: var(--distancer);

  border-left: 1px solid var(--color-n-gray);
}

.quote-job {
  color: var(--color-n-gray);
}

/**
 *
 */
.section-grid {
  display: grid;

  width: var(--width-big);
  margin: 0 auto calc(var(--distancer) * 3) auto;

  padding: 0 var(--distancer);

  align-items: center;
  grid-template-columns: 1fr 1fr;

  img {
    display: block;

    width: 400px;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: calc(var(--distancer) / 2);
    padding-bottom: 0;

    font-size: var(--font-size-big);
  }

  h2 small {
    display: block;

    text-transform: uppercase;

    color: var(--color-bad);

    font-size: var(--font-size);
  }

  h2 + p {
    margin-bottom: calc(var(--distancer) * 2);
  }

  p {
    font-size: var(--font-size-medium);
  }
}
