:root {
  --button-size--default: 45px;
  --button-size--small: 30px;
  --button-size--tiny: 25px;
}

.button {
  position: relative;

  display: inline-block;

  cursor: pointer;
  text-transform: lowercase;

  font-family: var(--font-family-mono);
}

.button--size-default {
  height: var(--button-size--default);
  padding: 0 calc(var(--distancer) / 2);

  border-width: 1px;
  border-style: solid;

  line-height: var(--button-size--default);
}

.button--size-small {
  height: var(--button-size--small);
  padding: 0 calc(var(--distancer) / 3);

  border-width: 1px;
  border-style: solid;

  font-size: var(--font-size);

  line-height: var(--button-size--small);
}

.button--size-tiny {
  padding: 0 var(--distancer-small);

  border-width: 0;

  font-size: var(--font-size-small);
  line-height: var(--button-size--tiny);
}

.button--size-medium {
  height: var(--button-size--default);
  padding: 0 calc(var(--distancer) / 2);

  border-width: 1px;
  border-style: solid;

  font-size: var(--font-size-medium);

  line-height: var(--button-size--default);
}

.button--is-disabled {
  opacity: 0.7;
}

.button,
.button-icon,
.button-label {
  border-radius: var(--radius-small);
}

.button:hover {
  /* transition: box-shadow 200ms; */

  box-shadow: 7px 7px 0 color(var(--color-black) a(10%));
}

.button:active {
  transform: translate(4px, 4px);

  box-shadow: 3px 3px 0 color(var(--color-black) a(10%));
}

.button-icon {
  margin-right: var(--distancer-small);
}

.button--type-default {
  color: var(--color-black);
  border-color: var(--color-black);
  background: var(--color-white);
}

.button--type-default:hover {
  color: var(--color-white);
  background-color: var(--color-black);
}

.button--type-primary {
  color: var(--color-white);
  border-color: var(--color-black);
  background-color: var(--color-black);
}

.button--type-secondary {
  color: var(--color-white);
  border-color: var(--color-n-blue);
  background-color: var(--color-n-blue);
}

.button--type-bad {
  color: var(--color-white);
  border-color: var(--color-n-red);
  background: var(--color-n-red);
}
