.content {
  padding-top: calc(var(--distancer) * 2);
  padding-bottom: calc(var(--distancer) * 2);

  composes: content from "../core.ui/utils.css";
}

.hero {
  text-align: center;

  background-color: var(--color-n-gray-light);
}

.hero h1,
.hero h2 {
  line-height: 1.3;
}

.hero h2 {
  margin-bottom: calc(var(--distancer) * 2);

  font-weight: normal;
}

@media (--phone) {
  .content {
    padding-top: var(--distancer);
    padding-right: calc(var(--distancer) / 2);
    padding-left: calc(var(--distancer) / 2);
  }
}

.tiers {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: var(--distancer);
}

@media (--phone), (--tablet) {
  .tiers {
    display: block;
  }
}

.for-all {
  margin-bottom: calc(var(--distancer) * 2);

  text-align: center;
}

.price {
  padding: var(--distancer);

  border: 1px solid var(--color-dark-gray);

  font-size: var(--font-size);

  composes: inner-border from "../core.ui/utils.css";

  h2 {
    margin-bottom: var(--distancer);
    padding-bottom: 0;

    color: var(--color-red);

    font-size: var(--font-size-huge);

    small {
      display: block;

      text-transform: lowercase;

      color: var(--color-black);

      font-family: var(--font-family-mono);
      font-size: var(--font-size);
      font-weight: normal;
    }
  }
}

.price:hover {
  background-color: var(--color-n-yellow);

  hr {
    background-color: var(--color-black);
  }
}

@media (--phone) {
  .price {
    margin-bottom: var(--distancer);
    padding: var(--distancer) calc(var(--distancer) / 2);
  }

  .price:last-child {
    margin-bottom: 0;
  }
}

@media (--tablet) {
  .price {
    margin-bottom: var(--distancer);
  }
}

.features {
  display: grid;

  width: 700px;

  margin: 0 auto var(--distancer) auto;

  grid-template-columns: auto auto auto;
  grid-column-gap: calc(var(--distancer) / 2);
}

.limits {
  display: grid;

  grid-template-columns: 1fr auto;
  grid-column-gap: calc(var(--distancer) / 2);
}

.submit-form {
  width: 438px;
}

@media (--phone), (--tablet) {
  .submit-form {
    width: auto;
  }
}
