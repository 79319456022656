:root {
  --height: 64px;
}

.cli {
  flex: 1;

  margin-bottom: 0;

  color: var(--color-black);

  border: 0;
  box-shadow: none;
}

.cli input,
.cli span,
.cli label {
  height: var(--height);

  color: var(--color-black);
  background: transparent;

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
  line-height: var(--height);
}

.success,
.error {
  position: absolute;
  top: calc(100% + var(--distancer) / 3);
  right: 0;
  left: 0;

  padding: var(--distancer-small);

  color: var(--color-white);
}

.success {
  background: var(--color-n-green);
}

.error {
  background: var(--color-n-red);
}
