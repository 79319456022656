.is-focus {
  background-color: var(--color-n-yellow);
}

.name,
.params {
  font-family: var(--font-family-mono);
}

.description {
  text-align: right;
}

.params {
  overflow: hidden;

  max-width: 180px;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.param {
  display: inline-block;

  margin-left: var(--distancer-small);

  color: var(--color-n-gray);
}

.param--is-active {
  background: var(--color-black);
}

.param--is-required {
  display: inline-block;

  width: var(--distancer-small);
  height: var(--distancer-small);
  margin-left: 3px;

  vertical-align: text-top;

  color: var(--color-black);
  border-radius: 50%;
  background-color: var(--color-n-red);
}
