:root {
  --height: 67px;
}

.members {
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0 var(--distancer) 0 0;
}

.member {
  position: relative;

  margin: 0 0 0 calc(var(--distancer) / 2);

  list-style-type: none;

  transition: opacity 0.2s ease, width 0.2s ease, height 0.2s ease;

  opacity: 0.3;
}

.member--is-me::after {
  position: absolute;
  z-index: 1;
  right: calc(-1 * var(--distancer-small));
  bottom: calc(-1 * var(--distancer-small));

  width: calc(var(--distancer-small) * 2);
  height: calc(var(--distancer-small) * 2);

  content: "";
  transition: background-color 150ms ease;

  border-radius: var(--radius-small);
  background-color: var(--color-n-red);
}

.member--is-connected {
  opacity: 1;
}

.member--is-connected.member--is-me::after {
  background-color: var(--color-n-green);
}

.member--is-connected.member--is-me-loading::after {
  background-color: var(--color-black);
}
