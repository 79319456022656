.column {
  overflow: hidden;

  padding-left: var(--distancer-small);

  border-left: 1px solid var(--color-n-gray);
}

.column .content {
  padding-right: var(--distancer);
}

.title {
  margin-bottom: calc(var(--distancer) / 2);
  padding-top: calc(var(--distancer) / 2);
  padding-left: calc(var(--distancer) / 3);

  text-transform: lowercase;

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
  font-weight: normal;
}

.title small {
  color: var(--color-n-gray);

  font-size: var(--font-size);
  font-weight: normal;
}
