.chart {
  position: relative;
}

.chart > svg {
  display: block;
}

.tooltip {
  transform: translateY(-50%);
}

.tooltip__legend {
  display: inline-block;

  width: 8px;
  height: 8px;

  border-radius: 50%;
}

.tooltip__title {
  margin-bottom: var(--distancer-small);

  color: var(--color-black);
}

.tooltip__item {
  margin-bottom: var(--distancer-small);

  color: var(--color-black);
}

.tooltip__item:last-child {
  margin-bottom: 0;
}
