.comment {
  position: relative;

  margin-bottom: calc(var(--distancer) / 3);
}

.avatar {
  float: left;

  margin-right: calc(var(--distancer) / 3);

  margin-bottom: var(--distancer-small);
}

.header {
  padding-right: calc(var(--distancer) / 3);

  composes: clearfix from "../../core.ui/utils.css";
}

.name {
  font-size: var(--font-size);
  font-weight: bold;
  line-height: 1;
}

.date {
  color: var(--color-n-gray);

  font-size: var(--font-size-small);
  line-height: 1.1;
}
