.list {
  margin: 0;
  padding: 0;

  border-top: 1px solid var(--color-n-gray);

  background-color: var(--color-white);
}

.list li {
  padding: calc(var(--distancer) / 3) calc(var(--distancer) / 2);

  list-style-type: none;

  font-size: var(--font-size);
}

.list--has-multiple li {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
}

.list--has-multiple li.group-name {
  height: 1px;
  margin-top: var(--distancer-small);
  margin-bottom: var(--distancer-small);

  padding-top: 0;
  padding-bottom: 0;

  background-color: var(--color-n-gray);
}

.group-name:first-child {
  margin-top: 0;
}
