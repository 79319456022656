.feedback {
  display: grid;

  width: var(--workspace-width);
  height: 100%;

  margin: 0 auto;
  grid-template-columns: 1fr 683px 1fr;
  grid-gap: 0;
}

.insights-wrapper {
  overflow: hidden;

  padding-top: var(--distancer);
  padding-right: calc(var(--distancer) - var(--distancer-small));
  padding-left: var(--distancer-small);

  border-right: var(--distancer-small) solid var(--color-white);
}

.quotes-wrapper {
  position: relative;

  overflow: hidden;

  border-left: 1px solid var(--color-n-gray);
  background-color: var(--color-white);
}

.chat-wrapper {
  overflow: hidden;
}
