.summary {
  display: grid;

  width: 45px;
  height: 20px;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 3px;
}

.summary > div {
  background-color: var(--color-item-select);
}

.summary__complete {
  background-color: var(--color-black);
}
