.metrics {
  padding-right: var(--distancer);
}

.distancer {
  display: grid;
  grid-template-columns: auto 683px;
  grid-gap: 0;

  height: var(--distancer);
}

.placeholder-chart {
  padding-left: calc(var(--distancer) - var(--distancer-small));

  border-left: 1px solid var(--color-n-gray);
  background-color: var(--color-white);
}
