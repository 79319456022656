:root {
  --input-height: 64px;
}

.input {
  position: relative;

  display: block;

  transition: opacity 150ms ease-in;
}

.input--is-disabled {
  opacity: 0.5;
}

/**
 * Text
 */

.input--type-text,
.input--type-password {
  display: flex;
  overflow: hidden;

  border: 1px solid var(--color-n-gray);
  border-radius: var(--radius-small);
}

.input--type-text + .input,
.input--type-password + .input {
  border-top: 0;
}

.input--type-text.input--has-error,
.input--type-password.input--has-error {
  border-color: var(--color-n-red);
}

.input--type-text span {
  position: absolute;
  z-index: var(--z-index-normal);
  top: 0;
  left: 0;

  width: 100%;

  opacity: 0.3;
}

.input--type-text input {
  z-index: var(--z-index-high);

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
}

.input--type-text input,
.input--type-text label,
.input--type-text .error,
.input--type-text span,
.input--type-password input,
.input--type-password label,
.input--type-password .error {
  height: var(--input-height);
  padding: 0 calc(var(--distancer) / 2);

  font-size: var(--font-size);
  line-height: var(--input-height);
}

.input--size-medium input,
.input--size-medium label,
.input--size-medium .error,
.input--size-medium span {
  font-size: var(--font-size-medium);
}

.input--type-text input,
.input--type-password input,
.input--type-text span {
  flex: 1;

  margin: 0;

  color: var(--color-black);
  border: 0;
  outline: none;
  background: var(--color-white);
  box-shadow: none;
}

.input--type-text .error,
.input--type-password .error {
  padding: 0 var(--distancer-small) 0 0;

  color: var(--color-bad);
  background: var(--color-white);
}

.input--type-text label,
.input--type-password label {
  text-transform: uppercase;

  color: var(--color-black);
  border-right: 1px solid var(--color-n-gray);

  font-weight: bold;
}

/**
 * Checkbox
 */

.input--type-checkbox label {
  display: block;

  height: var(--input-height);

  line-height: var(--input-height);
}

.input--type-checkbox input {
  display: inline-block;

  margin-top: 2px;
  margin-right: var(--distancer-small);
}
