.is-focus {
  background-color: var(--color-n-yellow);
}

.name,
.description,
.section-header {
  margin-bottom: calc(var(--distancer) / 2);
}

.name {
  font-family: var(--font-family-mono);
}

.param {
  display: inline-block;

  margin-left: var(--distancer-small);
}

.param--is-active {
  text-decoration: underline;
}

.param--is-required {
  display: inline-block;

  width: var(--distancer-small);
  height: var(--distancer-small);
  margin-left: 3px;

  vertical-align: text-top;

  color: var(--color-black);
  border-radius: 50%;
  background-color: var(--color-n-red);
}

.param-name,
.param-keyword {
  font-family: var(--font-family-mono);
}

.param-keyword {
  margin-right: var(--distancer-small);
  margin-bottom: var(--distancer-small);

  font-size: var(--font-size-small);
}

.param-description {
  margin-bottom: calc(var(--distancer) / 2);
  padding-left: 30px;

  font-family: var(--font-family);
}

.param-options {
  padding-left: 30px;
}

.param-options code {
  display: inline-block;

  padding: 0 var(--distancer-small);

  border: 1px solid var(--color-n-gray);
  border-radius: var(--radius-small);

  background-color: var(--color-white);
}
