:root {
  --menu-height: 65px;
}

.menu-distancer {
  height: var(--menu-height);
}

.menu-wrapper {
  position: fixed;
  z-index: var(--z-index-high);
  top: 0;
  left: 0;

  width: 100%;

  border-bottom: 1px solid var(--color-n-gray);
  background-color: var(--color-white);

  composes: outer-bottom-border from "/core.ui/utils.css";
}

.menu {
  display: grid;
  grid-template-columns: auto auto 120px 120px 120px 300px;

  width: var(--width-big);
  height: var(--menu-height);
  margin: 0 auto;
  padding: 0 var(--distancer);

  vertical-align: middle;

  line-height: var(--menu-height);
}

.menu li {
  list-style-type: none;
}

.menu .logo a,
.menu .logo a:active {
  color: var(--color-black);
}

.link {
  position: relative;

  display: block;

  height: 100%;

  cursor: pointer;
  text-align: center;
  text-transform: lowercase;

  color: var(--color-black);
  border-left: 1px solid var(--color-n-gray);

  font-family: var(--font-family-mono);
  font-size: var(--font-size);

  composes: inner-border from "/core.ui/utils.css";
}

.link:hover {
  text-decoration: none;

  background: var(--color-n-gray-light);
}

.menu li:last-child .link {
  border-right: 1px solid var(--color-n-gray);
}

@media (--phone) {
  .link {
    padding: 0 calc(var(--distancer) / 2);
  }
}

.link--is-active.link:hover,
.link--is-active {
  background: var(--color-n-yellow);
}

@media (--phone), (--tablet) {
  .link--login {
    display: none;
  }
}
