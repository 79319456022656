.focusers {
  position: absolute;
  z-index: var(--z-index-high);
  top: 0;
  right: -20px;

  line-height: 1;
}

.focusers--is-me-editing {
  top: 100%;
}

.focuser {
  position: relative;

  margin-bottom: var(--distancer-small);
}

.focuser:last-child {
  margin-bottom: 0;
}

.focuser--is-editing::after {
  position: absolute;
  right: calc(-1 * var(--distancer-small));
  bottom: calc(-1 * var(--distancer-small));

  height: 8px;
  padding-bottom: 3px;

  content: "...";
  animation: fadeInAndOut 1.3s infinite;

  border-radius: var(--radius-small);
  background: var(--color-black);
  box-shadow: var(--box-shadow);

  font-size: var(--font-size-tiny);
  line-height: 3px;
}

@keyframes fadeInAndOut {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}
