:root {
  --call-size: 5px;
}

.data-status {
  position: fixed;
  z-index: var(--z-index-highest);
  top: 0;
  left: 50%;

  display: none;

  padding: calc(var(--distancer) / 2);

  transform: translateX(-50%);
  text-align: center;

  background: var(--color-white);
  box-shadow: var(--box-shadow);
}

.data-status--is-visible {
  display: block;
}

.calls {
  position: absolute;

  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
  height: var(--call-size);
}

.call {
  height: var(--call-size);
  margin-right: 5px;

  flex-grow: 1;

  font-size: var(--font-size-small);
}

.call:last-child {
  margin-right: 0;
}

.call--create {
  background-color: var(--color-good);
}

.call--load {
  background-color: var(--color-blue);
}

.call--update {
  background-color: var(--color-yellow);
}

.call--remove {
  background-color: var(--color-bad);
}
