/* stylelint-disable no-descending-specificity */

.menu {
  height: 85px;

  vertical-align: middle;

  line-height: 85px;

  li {
    float: right;
  }

  .menu__logo {
    float: left;

    a,
    a:active {
      color: var(--color-black);
      background: transparent;
    }
  }
}

.content {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;

  composes: content from "/core.ui/utils.css";
}

.menu__link {
  display: block;

  padding: 0 var(--distancer);

  text-transform: uppercase;

  color: var(--color-black);

  font-size: var(--font-size);

  &:hover {
    text-decoration: none;

    background: var(--color-gray);
  }
}

.menu__link--demo {
  color: var(--color-white);
  background-color: var(--color-blue);

  &:hover {
    text-decoration: underline;

    color: var(--color-white);
    background-color: var(--color-blue);
  }
}
