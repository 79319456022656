.inner-border {
  position: relative;
  z-index: var(--z-index-normal);
}

.inner-border::after {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";

  border: var(--distancer-small) solid var(--color-white);
}

.outer-bottom-border::after {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  left: 0;

  height: 5px;

  content: "";

  background-color: var(--color-white);
}

.clearfix::after {
  display: block;
  visibility: hidden;
  clear: both;

  height: 0;

  content: " ";

  font-size: 0;
}

.content {
  width: var(--width-big);
  margin: 0 auto;
  padding: calc(var(--distancer) * 3) var(--distancer);

  composes: clearfix;
}

@media (--phone), (--tablet) {
  .content {
    width: 100%;
  }
}

.content--blue {
  background-color: var(--color-n-gray-light);
}

@keyframes fade {
  0% {
    display: none;

    opacity: 0;
  }

  1% {
    display: block;

    opacity: 0;
  }

  100% {
    display: block;

    opacity: 1;
  }
}

.success {
  padding: var(--distancer-small);

  border: 1px solid var(--color-good);
  border-radius: var(--radius-small);
  background-color: color(var(--color-good) a(10%));
}

.error {
  padding: var(--distancer-small);

  border: 1px solid var(--color-bad);
  border-radius: var(--radius-small);
  background-color: color(var(--color-bad) a(10%));
}
