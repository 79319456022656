.early-access--success {
  display: block;
}

.form {
  position: relative;
  z-index: var(--z-index-normal);

  display: flex;

  width: 500px;

  font-size: var(--font-size-medium);
}

@media (--phone) {
  .form {
    width: 100%;
  }
}

.form--is-center {
  margin: 0 auto;
}

.form__input {
  flex: 1;

  margin-right: calc(var(--distancer) / 2);
  margin-bottom: 0;

  border: 1px solid var(--color-n-gray);
  border-radius: var(--radius-small);
}

.form__input--name {
  display: inline-block;

  margin-bottom: 0;

  vertical-align: middle;
}

.followup {
  flex: 1;

  padding: calc(var(--distancer) / 2);

  border: 1px solid var(--color-n-gray);
  background-color: var(--color-white);
}

.followup p:last-child {
  margin-bottom: 0;
}
