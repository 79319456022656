:root {
  --code-bg-color: color(var(--color-black) a(8%));
}

.code .textarea {
  overflow: hidden;

  width: 100%;

  background-color: transparent;
}

:global(.ace_editor) {
  font-family: var(--font-family-mono);
}

.code {
  position: relative;

  border: 1px dashed transparent;
  border-radius: var(--radius-small);

  /**
   * prism.js default theme for JavaScript, CSS and HTML
   * Based on dabblet (http://dabblet.com)
   * @author Lea Verou
   */

  code[class*="language-"],
  pre[class*="language-"] {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-wrap: normal;
    word-break: normal;

    tab-size: 4;
    hyphens: none;

    color: var(--color-black);
    background: none;
    text-shadow: 0 1px var(--color-white);

    font-family: var(--font-family-mono);
    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  pre[class*="language-"]::selection,
  pre[class*="language-"] ::selection,
  code[class*="language-"]::selection,
  code[class*="language-"] ::selection {
    background: #B3D4FC;
    text-shadow: none;
  }

  /* Code blocks */
  pre[class*="language-"] {
    overflow: auto;

    margin: 0.5em 0;
    padding: 1em;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #F5F2F0;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em;

    white-space: normal;

    border-radius: 0.3em;
  }

  :global(.token.comment),
  :global(.token.prolog),
  :global(.token.doctype),
  :global(.token.cdata) {
    color: slategray;
  }

  :global(.token.punctuation) {
    color: #999;
  }

  :global(.token.namespace) {
    opacity: 0.7;
  }

  :global(.token.property),
  :global(.token.tag),
  :global(.token.boolean),
  :global(.token.number),
  :global(.token.constant),
  :global(.token.symbol),
  :global(.token.deleted) {
    color: #905;
  }

  :global(.token.selector),
  :global(.token.attr-name),
  :global(.token.string),
  :global(.token.char),
  :global(.token.builtin),
  :global(.token.inserted) {
    color: #690;
  }

  :global(.token.atrule),
  :global(.token.attr-value),
  :global(.token.keyword) {
    color: #07A;
  }

  :global(.token.function),
  :global(.token.class-name) {
    color: #DD4A68;
  }

  :global(.token.regex),
  :global(.token.important),
  :global(.token.variable) {
    color: #E90;
  }

  :global(.token.important),
  :global(.token.bold) {
    font-weight: bold;
  }

  :global(.token.italic) {
    font-style: italic;
  }

  :global(.token.entity) {
    cursor: help;
  }

  :global(.token.operator),
  :global(.token.entity),
  :global(.token.url),
  :global(.language-css .token.string),
  :global(.style .token.string) {
    color: #9A6E3A;
    background: hsla(0, 0%, 100%, 0.5);
  }

  :global(.token.keyword.code) {
    padding: 1px 0;

    color: var(--color-black);
    border-radius: var(--radius-small);
    background-color: var(--code-bg-color);
  }

  @media print {
    code[class*="language-"],
    pre[class*="language-"] {
      text-shadow: none;
    }
  }
}

.code *:first-child {
  margin-top: 0;
}

.code *:last-child {
  margin-bottom: 0;
}

.is-selected {
  border: 1px solid var(--color-black);

  /* background-color: var(--color-gray-2); */
}

.placeholder {
  color: #A0A0A0;
}

.is-view-mode {
  cursor: pointer;

  h1 {
    border-bottom: 1px solid var(--color-dark-gray);
  }

  h1,
  h2 {
    margin-top: calc(var(--distancer) / 2);
    margin-bottom: calc(var(--distancer) / 2);
    padding-bottom: calc(var(--distancer-small));
  }

  h3 {
    margin-top: calc(var(--distancer) / 3);
    margin-bottom: calc(var(--distancer) / 3);
    padding-bottom: 0;
  }

  h1,
  h2,
  h3 {
    font-size: var(--font-size-medium);
  }

  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0;
  }

  li code,
  p code,
  h1 code,
  h2 code,
  h3 code {
    display: inline-block;

    padding: 1px var(--distancer-small);

    border-radius: var(--radius-small);
    background-color: var(--code-bg-color);
  }

  h1 code,
  h2 code,
  h3 code {
    font-size: calc(var(--font-size-medium) - 1px);
  }

  pre {
    padding: var(--distancer-small);

    border-radius: var(--radius-small);
    background: var(--code-bg-color);

    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  pre code {
    background: none;

    /* Fira Code is bigger than Proxima */
    font-size: calc(var(--font-size) - 1px);
  }

  table {
    border: 1px solid var(--color-gray-3);
    border-radius: var(--radius-small);
  }

  table tr {
    border-bottom: 1px solid var(--color-gray-3);
  }

  table th {
    text-align: left;

    background-color: var(--color-gray-2);

    font-weight: bold;
  }

  table td {
    border-right: 1px solid var(--color-gray-3);
  }

  table td,
  table th {
    padding: calc(var(--distancer) / 3);
  }
}

.is-selected.is-edit-mode {
  border: 1px dashed var(--color-black);
}
