.tooltip {
  position: absolute;

  padding: var(--distancer-small) calc(var(--distancer) / 3);

  pointer-events: none;

  background-color: var(--color-white);
  box-shadow: var(--box-shadow);

  font-size: var(--font-size-small);
}

.tooltip__legend {
  display: inline-block;

  width: 8px;
  height: 8px;

  border-radius: 50%;
}

.tooltip__title {
  margin-bottom: var(--distancer-small);

  color: var(--color-black);
}

.tooltip__item {
  margin-bottom: var(--distancer-small);

  color: var(--color-black);
}

.tooltip__item:last-child {
  margin-bottom: 0;
}
