.contact {
  composes: content--blue from "/core.ui/utils.css";
}

@media (--phone), (--tablet) {
  .contact {
    margin-bottom: 0;
  }

  .h1 {
    margin-bottom: calc(var(--distancer) * 2);

    font-size: calc(var(--font-size-big) - 2px);
  }
}

.content {
  padding: calc(var(--distancer) * 2);

  composes: content from "/core.ui/utils.css";

  h1,
  h2 {
    text-align: center;

    line-height: 1.3;
  }

  h2 {
    margin-bottom: calc(var(--distancer) * 3);

    font-weight: normal;
  }

  code {
    display: inline-block;

    width: 150px;
    padding: 0 var(--distancer-small);

    color: var(--color-white);
    border-radius: var(--radius-small);
    background: var(--color-black);

    font-family: var(--font-family-mono);
    font-size: var(--font-size-normal);
  }
}

@media (--phone), (--tablet) {
  .content {
    height: auto;
    padding-top: var(--distancer);
    padding-bottom: var(--distancer);
  }
}

.team {
  display: grid;

  grid-template-columns: auto 500px;
  grid-column-gap: var(--distancer);
}

.team a img {
  display: inline-block;

  width: 25px;
  margin-right: calc(var(--distancer-small) * 2);
}

.team-contact strong {
  display: inline-block;

  width: 80px;
}

.team-contact a {
  color: var(--color-black);
}

@media (--phone), (--tablet) {
  .team {
    display: block;
  }
}

.pose {
  display: block;

  height: 300px;

  background: url("./ui/images/contact.png") no-repeat center center;
  background-size: contain;
}

@media (--phone), (--tablet) {
  .pose {
    display: none;
  }
}
