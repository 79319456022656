.metric {
  position: relative;

  padding-top: var(--distancer);
  padding-bottom: var(--distancer);
  padding-left: calc(var(--distancer) + 15px);

  border-top: 1px solid var(--color-n-blue);
  border-bottom: 1px solid var(--color-n-blue);
}

.metric:hover {
  background-color: var(--color-item-hover);
}

.metric:last-child {
  margin-bottom: 0;
}

.is-focus,
.is-focus.metric:hover {
  background-color: var(--color-item-focus);
}

.tag {
  position: absolute;
  top: -7px;
  left: var(--distancer);
}

.title {
  padding: calc(var(--distancer) / 3);

  font-weight: bold;
}

.legend {
  padding: calc(var(--distancer) / 3);
}

.chart {
  height: 150px;
  padding: calc(var(--distancer) / 2);
}

.chart--empty {
  padding: var(--distancer);

  text-align: center;

  font-size: var(--font-size-medium);
}

.chart--empty small {
  display: block;

  margin-top: calc(var(--distancer) / 2);

  font-size: var(--font-size-medium);
}
