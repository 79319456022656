.content {
  composes: content from "../core.ui/utils.css";
}

.feature-metrics {
  padding-bottom: 0;
}

.texts {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: calc(var(--distancer) * 2);
}

.texts h2 {
  color: var(--color-blue);
}

.texts p {
  margin-bottom: var(--distancer);
}

.use-instead {
  height: 24px;
}
