.group {
  position: relative;
}

.avatar:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.avatar:nth-child(2) {
  position: absolute;
  top: 21px;
  left: 5px;
}

.avatar-rest {
  position: absolute;
  top: 5px;
  left: 17px;

  color: var(--color-black);
  border: 1px solid var(--color-black);
  border-radius: 0;
  background-color: var(--color-white);

  font-size: var(--font-size-small);
}
