.tabs {
  margin: 0 0 var(--distancer) 0;
  padding: 0;

  border-bottom: 1px solid var(--color-dark-gray);

  composes: clearfix from "../utils.css";
}

.tab {
  height: var(--distancer);
  padding: 0 calc(var(--distancer) / 2);

  list-style-type: none;

  cursor: pointer;

  border-top-left-radius: var(--radius-small);
  border-top-right-radius: var(--radius-small);

  font-size: var(--font-size);

  line-height: var(--distancer);

  &:hover {
    background: var(--color-gray);
  }
}

.tab--is-active {
  color: var(--color-black);
  background-color: var(--color-medium-gray);

  &:hover {
    background: var(--color-medium-gray);
  }
}

.title {
  height: var(--distancer);

  list-style-type: none;

  color: var(--color-blue);

  font-size: var(--font-size-big);

  font-weight: bold;
  line-height: var(--distancer);
}

.title--is-link {
  cursor: pointer;
}

.tabs--align-right .tab {
  float: right;
}

.tabs--align-left .tab {
  float: left;
}

.tabs--align-right .title {
  float: left;
}

.tabs--align-left .title {
  float: right;
}
