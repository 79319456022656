.help {
  position: absolute;
  top: 64px;
  right: 0;

  width: calc(50% + var(--distancer));

  border: 1px solid var(--color-n-gray);
  background-color: var(--color-n-yellow);
  box-shadow: var(--box-shadow-big);
}

.group {
  padding: calc(var(--distancer) / 2);

  border-bottom: 1px solid var(--color-n-gray);
}

.group:last-child {
  border-bottom: none;
}

.group h3 {
  margin-bottom: calc(var(--distancer) / 2);

  text-transform: lowercase;

  color: var(--color-n-blue);

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
  font-weight: normal;
}

.group ul {
  margin: 0;
  padding: 0;
}

.group ul li {
  display: grid;

  margin: 0;
  margin-bottom: var(--distancer-small);

  list-style-type: none;

  font-size: var(--font-size);
  grid-template-columns: auto 190px;
}

.group ul li:last-child {
  margin-bottom: 0;
}

.key {
  margin-right: var(--distancer-small);
  padding: 0 var(--distancer-small);

  border: 1px solid var(--color-black);
  border-radius: var(--radius-small);

  font-family: var(--font-family-mono);
}
