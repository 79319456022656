.fields-wrapper {
  padding: var(--distancer);
  padding-left: var(--distancer-small);

  hr {
    margin-bottom: 0;
  }
}

.field-metric + .field-metric,
.field-quote + .field-quote,
.field-metric + .field-quote,
.field-quote + .field-metric {
  border-top: 0;
}

.field-title,
.field-markdown,
.field-image,
.field-component {
  padding-top: calc(var(--distancer) / 2);
  padding-right: calc(var(--distancer) / 3);
  padding-bottom: calc(var(--distancer) / 2);
  padding-left: calc(10px + var(--distancer) + 15px);
}

.field--has-gutter.field-markdown {
  padding-left: calc(var(--distancer) / 2);
}

.field-title h1 {
  margin-bottom: 0;
  padding-bottom: 0;

  border-bottom: 0;

  font-size: var(--font-size-big);
  font-weight: bold;
  line-height: var(--distancer);
}

.worker {
  position: relative;

  display: block;

  margin-left: calc(10px + var(--distancer) + 15px);

  line-height: var(--distancer);
}
