.footer {
  position: relative;

  padding-top: calc(var(--distancer) * 3);
  padding-bottom: 0;

  font-size: var(--font-size-medium);

  composes: content from "../../../core.ui/utils.css";
}

@media (--phone) {
  .footer {
    padding-right: calc(var(--distancer) / 2);
    padding-left: calc(var(--distancer) / 2);
  }
}

.footer svg {
  vertical-align: text-top;
}

.links {
  margin: 0 0 var(--distancer) 0;

  text-align: center;

  a {
    display: inline-block;
  }

  img {
    width: 30px;
  }
}

.hr {
  margin-top: calc(var(--distancer) * 4);
  margin-bottom: 0;
}

.terms {
  margin-top: calc(var(--distancer) * 3);

  text-align: center;
}

@media (--phone) {
  .terms a {
    display: block;
  }
}

.sound-bite span {
  margin-right: var(--distancer-small);
}

@media (--phone) {
  .sound-bite span {
    display: block;

    margin-right: 0;

    text-align: center;
  }
}
