.tag {
  display: inline-block;

  padding: 2px var(--distancer-small);

  color: var(--color-white);
  border-radius: var(--radius-small);

  font-family: var(--font-family-mono);
  font-size: var(--font-size-tiny);

  line-height: 1;
}

.tag--has-shadow {
  box-shadow: var(--box-shadow);
}

.tag--color-black {
  background-color: var(--color-black);
}

.tag--color-red {
  background-color: var(--color-n-red);
}

.tag--color-blue {
  background-color: var(--color-n-blue);
}

.tag--size-default {
  padding: var(--distancer-small) calc(var(--distancer) / 3);

  font-size: var(--font-size);
}

.placeholder {
  display: inline-block;

  width: 42px;

  border-radius: var(--radius-small);
  background-color: var(--color-item-select);
}
