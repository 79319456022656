.status {
  height: var(--distancer);
  margin-bottom: 0;
  padding-bottom: 0;

  text-transform: lowercase;

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
  font-weight: normal;
  line-height: var(--distancer);

  composes: clearfix from "../../core.ui/utils.css";
}

.status small {
  color: var(--color-dark-gray);

  font-size: var(--font-size);
}

.feature {
  margin-bottom: var(--distancer);
}
