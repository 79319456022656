:root {
  --shortcut-size: calc(var(--distancer) / 2 + 3px);
}

.switcher {
  display: grid;

  margin: 0;
  padding-left: 0;

  font-family: var(--font-family-mono);
  font-size: var(--font-size);
  grid-template-columns: 1fr 1fr 1fr;
}

.item {
  position: relative;

  width: 100%;
  height: 100%;
  padding-top: calc(var(--distancer) / 2);

  list-style-type: none;

  cursor: pointer;
  text-align: center;

  border-left: 1px solid var(--color-n-gray);

  font-size: var(--font-size-small);

  composes: inner-border from "/core.ui/utils.css";
}

.item:hover {
  background-color: var(--color-item-hover);
}

.item--is-focus,
.item--is-focus.item:hover {
  background-color: var(--color-item-focus);
}

.shortcut {
  display: block;

  width: var(--shortcut-size);
  height: var(--shortcut-size);

  margin: 0 auto var(--distancer-small) auto;

  color: var(--color-white);

  border-radius: var(--radius-small);
  background-color: var(--color-black);

  line-height: var(--shortcut-size);
}
