.content {
  padding: var(--distancer);

  composes: content from "/core.ui/utils.css";
}

.command-line {
  position: fixed;

  z-index: var(--z-index-higher);
  bottom: calc(var(--distancer) / 2);
  left: 50%;

  width: 700px;

  transform: translateX(-50%);

  border-bottom: 1px solid var(--color-black);
}
