.product {
  margin-bottom: var(--distancer);
}

.product pre {
  font-size: var(--font-size-small);
}

.name {
  padding-bottom: calc(var(--distancer) / 2);

  color: var(--color-blue);
  border-bottom: 1px solid var(--color-dark-gray);
}

.go-to-board {
  float: right;

  font-weight: normal;
}
