.features {
  overflow: hidden;

  padding: var(--distancer) var(--distancer) 0 var(--distancer);

  background-color: var(--color-white);
}

.cards {
  width: max-content;
  padding-bottom: var(--distancer);

  composes: clearfix from "../../core.ui/utils.css";
}

.card {
  float: left;
}

.title {
  height: var(--distancer);

  margin-bottom: var(--distancer);
  padding-bottom: 0;

  font-size: var(--font-size);
  font-weight: bold;
  line-height: var(--distancer);
}

.title small {
  color: var(--color-dark-gray);

  font-size: var(--font-size);
  font-weight: normal;
}
