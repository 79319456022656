.hero {
  position: relative;

  margin-bottom: calc(var(--distancer) * 2);
  padding-top: calc(var(--distancer) / 2);
}

.work {
  width: 726px;
  height: 460px;
  margin: 0 auto;

  background: transparent url("./images/hero-work.png") 0 0 no-repeat;
  background-size: contain;
}

.feedback,
.product,
.analytics {
  position: absolute;

  background: transparent 0 0 no-repeat;
  background-size: contain;
}

.feedback {
  z-index: 2;
  top: 80px;
  left: 0;

  width: 428px;
  height: 326px;

  background-image: url("./images/hero-feedback.png");
}

.product {
  z-index: 3;
  top: 45px;
  left: 400px;

  width: 343px;
  height: 328px;

  background-image: url("./images/hero-product.png");
}

.analytics {
  z-index: 1;
  right: 0;
  bottom: -40px;

  width: 530px;
  height: 287px;

  background-image: url("./images/hero-analytics.png");
}

.feedback:hover,
.product:hover,
.analytics:hover {
  z-index: 10;
}
