.legend {
  display: grid;

  grid-column-gap: calc(var(--distancer) / 3);

  font-size: calc(var(--font-size-small) - 1px);
}

.item__text {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-family: var(--font-family-mono);
}
