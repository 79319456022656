/* stylelint-disable no-descending-specificity */

.card-wrapper {
  padding: var(--distancer-small) 0;

  border-bottom: 1px solid var(--color-n-gray);
}

.card-wrapper:first-child {
  padding-top: 0;
}

.card {
  display: grid;

  padding: calc(var(--distancer) / 2);
  padding-left: calc(var(--distancer) / 2 - var(--distancer-small));

  cursor: pointer;

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;

  font-size: var(--font-size);

  grid-template-columns: var(--distancer) auto;
  grid-gap: calc(var(--distancer) / 2);
}

.card-wrapper:hover .card {
  background-color: var(--color-item-hover);
}

.card-wrapper.card-wrapper--is-select .card,
.card-wrapper.card-wrapper--is-select:hover .card {
  background-color: var(--color-item-select);
}

.card-wrapper.card-wrapper--is-focus .card,
.card-wrapper.card-wrapper--is-focus.card-wrapper--is-select .card,
.card-wrapper.card-wrapper--is-focus.card-wrapper:hover .card {
  background-color: var(--color-item-focus);
}

.card-body {
  margin-bottom: calc(var(--distancer) / 2);
}

.card__quotes-count {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-small);
}

.card__score {
  display: inline-block;

  width: var(--distancer);
  height: var(--distancer);
  padding: var(--distancer-small);

  text-align: center;
  vertical-align: middle;

  color: var(--color-white);
  border-radius: var(--radius-small);
  background-color: var(--color-n-blue);

  font-family: var(--font-family-mono);
}

.card--is-inbox .card__score {
  background-color: var(--color-black);
}

.card--is-inbox .card__quotes-count {
  color: var(--color-n-red);
}

.card__quote:last-child {
  border-bottom: 0;
}

.card__quote-body {
  margin-bottom: calc(var(--distancer) / 3);
}
