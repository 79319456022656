@font-face {
  font-family: "Fira Code";
  src:
    url("./_fonts/FiraCode-Regular.woff2") format("woff2"),
    url("./_fonts/FiraCode-Regular.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src:
    url("./_fonts/FiraCode-Bold.woff2") format("woff2"),
    url("./_fonts/FiraCode-Bold.woff") format("woff");

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;

  font-weight: normal;
  font-style: normal;
  src:
    url("./_fonts/Inter-Regular.woff2?v=3.13") format("woff2"),
    url("./_fonts/Inter-Regular.woff?v=3.13") format("woff");
}

@font-face {
  font-family: Inter;
  font-display: swap;

  font-weight: normal;
  font-style: italic;
  src:
    url("./_fonts/Inter-Italic.woff2?v=3.13") format("woff2"),
    url("./_fonts/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: Inter;
  font-display: swap;

  font-weight: bold;
  font-style: normal;
  src:
    url("./_fonts/Inter-Bold.woff2?v=3.13") format("woff2"),
    url("./_fonts/Inter-Bold.woff?v=3.13") format("woff");
}
