.overlay {
  position: fixed;
  z-index: var(--z-index-highest);
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.9);
}

.background {
  position: absolute;
  z-index: 1;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  padding: 10vh 10vw;

  text-align: center;
  pointer-events: none;
}

.props {
  position: absolute;
  bottom: calc(100% - 10vh);
  left: 0;

  width: 100%;
  padding-bottom: var(--distancer-small);

  pointer-events: all;
}

.props span::selection {
  color: var(--color-n-yellow);
  background: var(--color-black);
}

.props span {
  display: inline-block;

  margin-right: var(--distancer-small);
  padding: var(--distancer-small);

  background-color: var(--color-n-yellow);

  font-family: var(--font-family-mono);
  font-size: var(--font-size-small);
}

.content img {
  max-width: 100%;
  max-height: 100%;

  pointer-events: all;

  border: 1px solid var(--color-n-gray);
  border-radius: var(--radius-small);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-big);
}
