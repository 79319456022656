:root {
  --title-line-height: 16px;
}

.card {
  position: relative;

  width: 100%;
  margin-right: var(--distancer);
  padding: var(--distancer-small);

  cursor: pointer;

  border: 1px solid var(--color-n-gray);

  composes: inner-border from "/core.ui/utils.css";

  &::after {
    border: 5px solid var(--color-n-gray-light);
  }
}

.card:last-child {
  margin-right: 0;
}

.card:hover {
  background-color: var(--color-item-hover);
}

.card--is-select,
.card--is-select.card:hover {
  background-color: var(--color-item-select);
}

.card--is-focus,
.card--is-focus.card:hover {
  background-color: var(--color-item-focus);
}

.card--with-cover {
  display: grid;
  grid-template-columns: 114px auto;
  grid-column-gap: 0;
  grid-template-rows: 114px;
}

.cover {
  position: relative;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.card--is-select .cover,
.card--is-focus .cover,
.card:hover .cover {
  mix-blend-mode: multiply;
}

.body {
  position: relative;

  padding: calc(var(--distancer) / 3);

  composes: clearfix from "/core.ui/utils.css";
}

.title {
  display: block;
  overflow: hidden;

  height: calc(var(--title-line-height) * 2);
  margin-top: 0;
  margin-bottom: calc(var(--distancer) / 2);

  text-overflow: ellipsis;
  word-wrap: break-word;

  font-size: var(--font-size);
  font-weight: normal;
  line-height: var(--title-line-height);
}

.avatar-placeholder {
  display: inline-block;

  width: 20px;
  height: 20px;

  border-radius: var(--radius-small);
  background-color: var(--color-item-select);
}

.card--is-select .avatar,
.card--is-focus .avatar,
.card:hover .avatar {
  mix-blend-mode: multiply;
}

.tags {
  margin-bottom: calc(var(--distancer) / 3);

  line-height: 1;
}

.tag {
  margin-right: var(--distancer-small);
}

.status-hist {
  position: absolute;

  right: calc(var(--distancer) / 3);
  bottom: calc(var(--distancer) / 3);
}
